import React, {Component} from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ModalBoot  from '../components/Modal.js';
import parse from 'html-react-parser';
import {dynamicSort} from '../functions/sort.js';
import ItemOverview from './ItemOverview.js';
import JoinPage from './JoinPage.js';
import $ from 'jquery';
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');





export default class ListPageFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            items:[],
            devoPics: [],
            settings: {_id: "df"},
            userInfo: this.props.userInfo,
            selected_item: {},
            show_filters: true,
            sort_items: "",
            filter_filter: "",
            selected_filter: "historic",
            search_item: "",
            surf_points: 0,
            filter: []
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.showFormButtonItem = this.showFormButtonItem.bind(this);
        this.selectCity = this.selectCity.bind(this);
        this.changeState = this.changeState.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    componentDidMount() {
        $('.container').after('<style>.navbar{display:none;}</style>');  
        $(window).scroll(function() {
            if($(window).scrollTop() > ($(".viewTrap").offset().top)){
                $(".page_content").addClass("viewActive");
            }else if($(window).scrollTop() < ($(".viewTrap").offset().top - 140)){
                $(".page_content").removeClass("viewActive");
            };
        });
        axios.get(localhostName.host + '/filter_data')
            .then(res => {
                console.log(res);
                if(res.data.length >0){
                    let items = JSON.parse(res.data);
                    items.sort(dynamicSort("name"));
                    items.map((c)=>{
                        if(c.filter == undefined){
                            c.filter = {};
                        };
                    });
                    this.setState({items: items});
                };
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });           
    }
    copy_paste(value){
        var inp = document.createElement('input');
        document.body.appendChild(inp)
        inp.value = value;
        inp.select();
        document.execCommand('copy',false);
        inp.remove();
    }
    updateContents(){
        axios.get(localhostName.host + '/filter_data')
            .then(res => {
                if(res.data.length >0){
                    this.setState({items: res.data});
                };
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });       
    }
    save_data(){
        let items = this.state.items;
        window.updated_items = JSON.stringify(items);
        axios.post(localhostName.host + '/update/filter_data', items)
            .then(res => {
                alert("Ok, gespeichert. Du Idiot.");
            })
            .catch(function (error) {
                console.log(error);
            });       
    }
    showFormButton(targetShow,change){
        this.setState({ [targetShow]: change });
    }
    showFormButtonItem(targetShow,change,parent){
        this.setState({ [targetShow]: change, parent: parent });
        console.log(parent);
        console.log(targetShow);
    }
    selectCity(item){
        this.setState({ selected_item: item });
    }
    changeState(state,value){
        this.setState({ [state]: value });
    }
    add_remove_filter(name,value){
        let filter = this.state.filter;
        let filter_index = filter.findIndex((filter)=>filter.name == name);
        filter[filter_index] = value;
        if(filter_index == -1){filter.push({name: name, value: value})};
        this.setState({ filter: filter });
    }
    renderCities(){
        // Rendering all projects; 8 projects per page
        let Cities = this.state.items;
        Cities = Cities.filter((c)=>c.name.toLowerCase().includes(this.state.search_item.toLowerCase()) );
        let sel_filt = this.state.selected_filter;
        if(this.state.filter_filter === "none"){
            Cities = Cities.filter((c)=> (c.filter || {})[sel_filt] === "" || (c.filter || {})[sel_filt] === undefined);
        };
        if(this.state.filter_filter === "true"){
            Cities = Cities.filter((c)=> (c.filter || {})[sel_filt] == 'true');
        };
        if(this.state.filter_filter === "false"){
            Cities = Cities.filter((c)=> (c.filter || {})[sel_filt] == 'false');
        };
        let lang = this.state.language;
        let score_color = function(item,name){
            let score_index = (item.scores || []).findIndex((score)=>score.name == name);
            let score_rate = 5;
            if(score_index > -1){
                score_rate = (item.scores[score_index].score / item.scores[score_index].max_score * 100);
            };
            if (score_rate > 85){
                return "#2bde73";
            }else if (score_rate > 65){
                return "#2bde73";
            }else if (score_rate > 45){
                return "#ffc924";
            }else if (score_rate > 30){
                return "#ff4742";
            }
            else{
                return "#ff4742";
            }
        };
        let score_rate = function(item,name){
            let score_index = (item.scores || []).findIndex((score)=>score.name == name);
            let score_rate = 5;
            if(score_index > -1){
                score_rate = (item.scores[score_index].score / item.scores[score_index].max_score * 100);
            };
            return score_rate;
        };
        let counter = 0;
        let countries = {UK: "United Kingdom", DE: "Germany", ES: "Spain", PT: "Portugal",FR: "France", IE: "Ireland", IT: "Italy", TR: "Turkey", NL: "Netherlands", 
        US: "USA", MX: "Mexico",CA: "Canada",AU: "Australia", NZ: "New Zealand", JP: "Japan", CR: "Costa Rica",DO: "Dominican Republic", TH: "Thailand", CO: "Colombia", KR: "South Korea", VN: "Vietnam", BR: "Brazil", AR: "Argentina", CH: "Switzerland", AT: "Austria"  };
        Cities.sort(dynamicSort("country"));
        console.log(JSON.stringify(Cities));
        return Cities.map((item) => {
            counter += 1;
            console.log(item.filter[this.state.selected_filter]);
            let filter_state = item.filter[this.state.selected_filter];
            return (
                <div className="item_box" > 
                    <div className="item_box_card" style={{background: 'url(./items_thumbs/' + encodeURIComponent(item.name)  + '.jpg)'}}>
                        <div className="overlay"></div>
                        <div className="top_row">
                            <div className="rank">{counter}</div>
                            <div className="total_score"><i className="fa fa-cubes"></i> {8.4}</div>
                        </div>
                        <div className="item_location">
                            <div className="item_name" onClick={()=>this.copy_paste(item.name)}>{item.name}</div>
                            <div className="item_state">{(item.country_master || "").length > 0 ? item.country_master  : countries[item.country]}</div>
                            <div className="">{item.country}</div>
                        </div>
                        <div className="item_card_bottom">
                            <div className="item_population">
                                {this.state.selected_filter}
                            </div>
                            <div className="monthly_costs" name={item.name + "filter"} onChange={(e)=>this.update_filter(item.name,this.state.selected_filter,e.target.value)} value={filter_state}>
                                <input type="radio" name={item.name + "filter"} value={true} checked={filter_state == "true"} /> True
                                <input type="radio" name={item.name + "filter"} value={false} checked={filter_state == "false"} /> False
                            </div>
                        </div>
                    </div>
                    <style>
                        {".item_box_card .overlay {background: #0000007d;}.monthly_costs input{margin-left: 10px;}.item_box{cursor: auto;}.item_name{cursor: context-menu;}"}
                    </style>
                </div>
            );
        });
    }
    changeSurfPoints(change){
        let surf_points = this.state.surf_points + Number(change);
        this.setState({ surf_points: surf_points });
    }
    filter_filter(value){
        this.setState({filter_filter: value});
    }
    select_filter(value){
        this.setState({selected_filter: value});
    }
    update_filter(item,filter,value){
        console.log(value);
        let items = this.state.items;
        let index = items.findIndex((c)=>c.name === item);
        items[index].filter[filter] = value;
        console.log(items[index]);
        this.setState({items: items});
    }
    search_item(e){
        this.setState({search_item: e.target.value})
    }
    render() {
        let sel_filt = this.state.selected_filter;
        let filter = this.state.filter;
        let check_filter = function(name){
            let filter_index = filter.findIndex((filter)=>filter.name == name);
            return ((filter[filter_index] || {value: false}).value ? "selected" : "not_selected");
        };
        let filter_value = function(name){
            let filter_index = filter.findIndex((filter)=>filter.name == name);
            return (filter[filter_index] || {value: false}).value;
        };
        if(this.state.surf_points < 6)
        {return (
            <div className="items_page">
                <div className="page_content">
                    <div className="viewTrap"></div>
                    {this.state.show_filters ?
                    <div className="filter_navbox">
                        <div className="navbar_mastertitle main_title">
                            {"Filters"}
                            <i className="nav_line"></i>
                        </div>
                        <div className="box">
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Misc
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div className={sel_filt == "beach" ? "selected" : ""} id="beach" onClick={(e)=>this.select_filter(e.target.id)}>💃 Beach</div>
                                    <div className={sel_filt == "mountains" ? "selected" : ""} id="mountains" onClick={(e)=>this.select_filter(e.target.id)}>⛱ Mountains</div>
                                    <div className={sel_filt == "green" ? "selected" : ""} id="green" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Green</div>
                                    <div className={sel_filt == "big_lake" ? "selected" : ""} id="big_lake" onClick={(e)=>this.select_filter(e.target.id)}>🌈 Big Lake</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    How
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>💃 Good nightlife</div>
                                    <div>⛱ Near beach</div>
                                    <div>🙂 Friendly people</div>
                                    <div>🌈 LGBTQ+ friendly</div>
                                    <div>🤲 Low in racism</div>
                                    <div>🍹 Many events</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Golf
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div id="many_courses"><img className="image_icon" src="/icons/golf.png" />Many Golf Courses</div>
                                    <div><img className="image_icon" src="/icons/quality.png" />High Quality of Courses</div>
                                    <div><img className="image_icon" src="/icons/earth.png" />World Top 100 Courses</div>
                                    <div><img className="image_icon" src="/icons/europe.png" />Europe Top 100 Courses</div>
                                    <div><img className="image_icon" src="/icons/asia.png" />Asia Top 100 Courses</div>
                                    <div><img className="image_icon" src="/icons/championship.png" />PGA Championship</div>
                                    <div><img className="image_icon" src="/icons/tournament.png" />The Open Championship</div>
                                    <div><img className="image_icon" src="/icons/tournament2.png" />US Masters</div>
                                    <div><img className="image_icon" src="/icons/tournament3.png" />U.S. Open</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Cost
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>💃 Cheap Hotels</div>
                                    <div>⛱ Cheap Friends</div>
                                    <div>🙂 Cheap Nightlife</div>
                                    <div>🌈 Cheap Flights</div>
                                    <div>🌈 Cheap Rentals</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                        <div className="navbar_mastertitle">
                                            How
                                            <i className="nav_line"></i>
                                        </div>
                                        <div className="navbar_row unbound">
                                            <div className={sel_filt == ("high_safety") ? "selected" : ""} onClick={() => { this.select_filter("high_safety") }}><img className="image_icon" src="/icons/safety.png" />Very safe</div>
                                            <div className={sel_filt == ("quality_life") ? "selected" : ""} onClick={() => { this.select_filter("quality_life") }}><img className="image_icon" src="/icons/quality-of-life.png" />High Quality of Life</div>
                                            <div className={sel_filt == ("english_speaking") ? "selected" : ""} onClick={() => { this.select_filter("english_speaking") }}><img className="image_icon" src="/icons/englishspeaking.png" />English speaking</div>
                                            <div className={sel_filt == ("good_nightlife") ? "selected" : ""} onClick={() => { this.select_filter("good_nightlife") }}><img className="image_icon" src="/icons/drink.png" />Good nightlife</div>
                                            <div className={sel_filt == ("wealthy") ? "selected" : ""} onClick={() => { this.select_filter("wealthy") }}><img className="image_icon" src="/icons/gold-bars.png" />Wealthy</div>
                                            <div className={sel_filt == ("many_events") ? "selected" : ""} onClick={() => { this.select_filter("many_events") }}><img className="image_icon" src="/icons/events.png" />Many events</div>
                                        </div>
                                    </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Sightseeing
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div className={sel_filt == "historic" ? "selected" : ""} id="historic" onClick={(e)=>this.select_filter(e.target.id)}>💃 Historic</div>
                                    <div className={sel_filt == "many_museums" ? "selected" : ""} id="many_museums" onClick={(e)=>this.select_filter(e.target.id)}>⛱ Museums</div>
                                    <div className={sel_filt == "many_art_galleries" ? "selected" : ""} id="many_art_galleries" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Art Galleries</div>
                                    <div className={sel_filt == "natural_wonder" ? "selected" : ""} id="natural_wonder" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Natural Wonder</div>
                                    <div className={sel_filt == "events" ? "selected" : ""} id="events" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Events</div>
                                    <div className={sel_filt == "many_skyscrapers" ? "selected" : ""} id="many_skyscrapers" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Skyscrapers</div>
                                    <div className={sel_filt == "historic_architecture" ? "selected" : ""} id="historic_architecture" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Historic Architecture / Houses</div>
                                    <div className={sel_filt == "unesco" ? "selected" : ""} id="unesco" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Unesco</div>
                                    <div className={sel_filt == "old_churches" ? "selected" : ""} id="old_churches" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Old Churches</div>
                                    <div className={sel_filt == "mosques" ? "selected" : ""} id="mosques" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Mosques</div>
                                    <div className={sel_filt == "buddhist_temples" ? "selected" : ""} id="buddhist_temples" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Buddhist Temples</div>
                                    <div className={sel_filt == "many_statues" ? "selected" : ""} id="many_statues" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Many Statues</div>
                                    <div className={sel_filt == "many_tourists" ? "selected" : ""} id="many_tourists" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Many Tourists</div>
                                    <div className={sel_filt == "roman_item" ? "selected" : ""} id="roman_item" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Roman City</div>
                                    <div className={sel_filt == "castles" ? "selected" : ""} id="castles" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Castles</div>
                                    <div className={sel_filt == "exotic_animals" ? "selected" : ""} id="exotic_animals" onClick={(e)=>this.select_filter(e.target.id)}>🙂 Exotic Animals</div>
                                    <div className={sel_filt == "high_mountain" ? "selected" : ""} id="high_mountain" onClick={(e)=>this.select_filter(e.target.id)}>🙂 {"High Mountain (>3000 m)"}</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                        <div className="navbar_mastertitle">
                                            Activities
                                            <i className="nav_line"></i>
                                        </div>
                                        <div className="navbar_row unbound">
                                            <div className={this.state.filter.includes("skiing") ? "selected" : ""} onClick={() => { this.select_filter("skiing") }}><img className="image_icon" src="/icons/ski.png" />Ski</div>
                                            <div className={this.state.filter.includes("jet_ski") ? "selected" : ""} onClick={() => { this.select_filter("jet_ski") }}><img className="image_icon" src="/icons/jet-ski.png" />Jet Ski</div>
                                            <div className={this.state.filter.includes("sailing") ? "selected" : ""} onClick={() => { this.select_filter("sailing") }}><img className="image_icon" src="/icons/sailing.png" />Sailing</div>
                                            <div className={this.state.filter.includes("hiking") ? "selected" : ""} onClick={() => { this.select_filter("hiking") }}><img className="image_icon" src="/icons/hiking.png" />Hiking</div>
                                            <div className={this.state.filter.includes("rock_climbing") ? "selected" : ""} onClick={() => { this.select_filter("rock_climbing") }}><img className="image_icon" src="/icons/climb.png" />Rock Climbing</div>
                                            <div className={this.state.filter.includes("fishing") ? "selected" : ""} onClick={() => { this.select_filter("fishing") }}><img className="image_icon" src="/icons/fishing.png" />Fishing</div>
                                            <div className={this.state.filter.includes("surfing") ? "selected" : ""} onClick={() => { this.select_filter("surfing") }}><img className="image_icon" src="/icons/surfing.png" />Surfing</div>
                                            <div className={this.state.filter.includes("canoeing") ? "selected" : ""} onClick={() => { this.select_filter("canoeing") }}><img className="image_icon" src="/icons/safety.png" />Canoeing</div>
                                            <div className={this.state.filter.includes("paragliding") ? "selected" : ""} onClick={() => { this.select_filter("paragliding") }}><img className="image_icon" src="/icons/parag.png" />Paragliding</div>
                                            <div className={this.state.filter.includes("bungee_jumping") ? "selected" : ""} onClick={() => { this.select_filter("bungee_jumping") }}><img className="image_icon" src="/icons/bungee.png" />Bungee Jumping</div>
                                            <div className={this.state.filter.includes("scuba_diving") ? "selected" : ""} onClick={() => { this.select_filter("scuba_diving") }}><img className="image_icon" src="/icons/diving.png" />Scuba diving</div>
                                            <div className={this.state.filter.includes("horseback_riding") ? "selected" : ""} onClick={() => { this.select_filter("horseback_riding") }}><img className="image_icon" src="/icons/horse.png" />Horseback riding</div>
                                            <div className={this.state.filter.includes("kitesurfing") ? "selected" : ""} onClick={() => { this.select_filter("kitesurfing") }}><img className="image_icon" src="/icons/kite.png" />Kitesurfing</div>
                                            <div className={this.state.filter.includes("off-road_racing") ? "selected" : ""} onClick={() => { this.select_filter("off-road_racing") }} ><img className="image_icon" src="/icons/racing.png" />Off-road racing</div>
                                            <div className={this.state.filter.includes("safari") ? "selected" : ""} onClick={() => { this.select_filter("safari") }}><img className="image_icon" src="/icons/safari.png" />Safari</div>
                                            <div className={this.state.filter.includes("hot_air_ballon") ? "selected" : ""} onClick={() => { this.select_filter("hot_air_ballon") }}><img className="image_icon" src="/icons/baloon.png" />Hot Air Balloon</div>
                                            <div className={this.state.filter.includes("desert_walking") ? "selected" : ""} onClick={() => { this.select_filter("desert_walking") }}><img className="image_icon" src="/icons/desert.png" />Desert Walking</div>
                                        </div>
                                    </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Health
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>💃 Clean Air</div>
                                    <div>🙂 Great Hospitals</div>
                                    <div>⛱ No smoking</div>
                                    <div>💃 Not overweight</div>
                                    <div>🙂 Food Safety</div>
                                    <div>⛱ Many beauty clinics</div>
                                    <div>⛱ Great Dentists</div>
                                    <div>⛱ Hairtransplantation</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Beauty & Wellness
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>💃 Many Spas</div>
                                    <div>🙂 Beauty Parlors</div>
                                    <div>⛱ Sauna culture</div>
                                    <div>💃 Many Hamams</div>
                                    <div>🙂 Acupuncture</div>
                                    <div>⛱ Many Massage Parlors</div>
                                    <div>⛱ Diet Coaches</div>
                                    <div>⛱ Fashion City</div>
                                    <div>⛱ Luxury Fashion Shops</div>
                                    <div>⛱ Fashion Events</div>
                                    <div>⛱ Cheap Hairtransplantation</div>
                                    <div>⛱ Cheap beauty clinics</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Food
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>🙂 Unesco Food City</div>
                                    <div>🙂 Street Food</div>
                                    <div>💃 Michelin 5 Stars Restaurants</div>
                                    <div>⛱ Food Safety</div>
                                    <div>🙂 Local Fruits & Vegetables</div>
                                    <div>🙂 Meat Quality</div>
                                    <div>🙂 For Cheese Lovers</div>
                                    <div>🙂 For Meat Lovers</div>
                                    <div>🙂 For Rice Lovers</div>
                                    <div>🙂 Low pork consumption</div>
                                    <div>🙂 Vegeterian</div>
                                    <div>🙂 Low wheat consumption</div>
                                    <div>🙂 High wheat consumption</div>
                                    <div>🙂 For Dessert Lovers</div>
                                    <div>🙂 For Pizza Lovers</div>
                                    <div>🙂 For Dumbling Lovers</div>
                                    <div>🙂 High yogurt consumption</div>
                                    <div>🙂 For Sushi Lovers</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Education
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>🙂 Highly educated</div>
                                    <div>💃 Nature Index (Top 50)</div>
                                    <div>⛱ World Top 100 Unis</div>
                                    <div>🙂 Nobel prize winner</div>
                                    <div>🙂 PISA Test</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Development
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row three_bound">
                                    <div>💃 Developed</div>
                                    <div>🙂 Developing</div>
                                    <div>⛱ Underdeveloped</div>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>💃 Freedom of speech</div>
                                    <div>⛱ Democratic</div>
                                    <div>🙂 Highly educated</div>
                                    <div>🌈 Wealthy</div>
                                    <div>🌈 High life expectancy</div>
                                    <div>🌈 Speaks English</div>
                                    <div>🌈 Great Infrastructure</div>
                                    <div>🌈 High Salaries</div>
                                    <div>🌈 Gender Equality</div>
                                    <div>🌈 Legal weed</div>
                                    <div>🌈 Legal abortion</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Climate
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row five_bound">
                                    <div>💃 Tropical climate</div>
                                    <div>⛱ Desert</div>
                                    <div>🙂 Not rainy</div>
                                    <div>🙂 Low humidity</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Infrastructure & Transport
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>💃 Large Metro Network</div>
                                    <div>⛱ Great Public Transport</div>
                                    <div>🙂 Airports</div>
                                    <div>🙂 Walkable</div>
                                    <div>🙂 Traffic Safety</div>
                                    <div>🙂 Many Taxis</div>
                                    <div>🙂 Bike Friendly</div>
                                    <div>🙂 Cheap Car Rentals</div>
                                    <div>🙂 Cheap Camper Rentals</div>
                                    <div>🙂 Highway No Speed Limit</div>
                                    <div>🙂 High Speed Trains</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Economy
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>💃 High GDP Capita</div>
                                    <div>⛱ Low unemployment</div>
                                    <div>🙂 Innovative</div>
                                    <div>🙂 Easy doing business</div>
                                    <div>🙂 Many Millionaires</div>
                                    <div>🙂 Finance Centre (Top 30)</div>
                                    <div>🙂 Tech Hub</div>
                                    <div>🙂 Stock Market</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Language
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>🙂 Speaks English</div>
                                    <div>🙂 Speaks Turkish</div>
                                    <div>💃 Speaks German</div>
                                    <div>⛱ Speaks Spanish</div>
                                    <div>🙂 Speaks French</div>
                                    <div>🙂 Speaks Arabic</div>
                                </div>
                            </div>
                            <div className="masterbox">
                                <div className="navbar_mastertitle">
                                    Luxury
                                    <i className="nav_line"></i>
                                </div>
                                <div className="navbar_row unbound">
                                    <div>🙂 High Class Hotels</div>
                                    <div>🙂 High Class Restaurants</div>
                                    <div>💃 Michelin 5 Stars Restaurants</div>
                                    <div>⛱ Luxury Shops</div>
                                    <div>🙂 Yachts</div>
                                    <div>🙂 Luxury Cars</div>
                                    <div>🙂 High Class Nightlife</div>
                                    <div>🙂 Many Millionaires</div>
                                    <div>🙂 Many Billionaires</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :""}
                    <Container className="container_items" >
                        <div className="content_topbar">
                            <div className="filter_button">
                                {!this.state.show_filters ? <button className="show_filter" onClick={()=>{this.changeState("show_filters",true)}}>Filters</button> : ""}
                                {this.state.show_filters ? <button className="hide_filter" onClick={()=>{this.changeState("show_filters",false)}}>Close Filters</button> : ""}
                            </div>
                            <button onClick={()=>this.filter_filter("")}>Alle</button>
                            <button onClick={()=>this.filter_filter("none")}>Noch nicht bewertet</button>
                            <button onClick={()=>this.filter_filter("true")}>Nur True</button>
                            <button onClick={()=>this.filter_filter("false")}>Nur False</button>
                            <button onClick={()=>this.save_data()}>SAVE</button>
                            <div className="sort_by">
                                <select>
                                    <optgroup>
                                        <option>Sort</option>
                                        <option>Noch nicht bewertet</option>
                                        <option>True</option>
                                        <option>False</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div>
                            Suche <input placeholder="City Name" onChange={(e)=>{this.search_item(e)}} value={this.state.search_item} />
                        </div><br/>
                        <div className="">
                            <div className="items_sorted">
                                {this.renderCities()}  
                            </div>
                            <button onClick={()=>this.save_data()}>SAVE</button>
                        </div>
                        <ModalBoot show={this.state.showItemOverview} hide={this.showFormButton} content={"showItemOverview"} className="modal_ItemOverview">
                            <ItemOverview selected_item={this.state.selected_item} />
                        </ModalBoot >
                    </Container>
                </div>
            </div>       
        )}
        else{
            return(
                <JoinPage  />
            )
        }
    }
}    