import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

function readableDollarValue(a) {
    let number = Number(a);
    number = Math.round(number);
    return number.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
    });
}

export default class RangeSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            min: this.props.min,
            max: this.props.max
        };
    }
    componentDidMount() {
        const rangeInput = document.querySelectorAll("." + this.props.name + " .range-input input"),
            range = document.querySelector("." + this.props.name + " .slider .progress");
        let priceGap = this.props.gap;

    }
    getInput(e){
        if(e.target.name === "min"){
            let calc = ((e.target.value - this.props.min) / (this.props.max - this.props.min)) * 100;
            $("." + this.props.name + " .progress").css("left", (calc + "%"));
        }else{
            let calc = (1 - ((e.target.value - this.props.min) / (this.props.max - this.props.min))) * 100;
            $("." + this.props.name + " .progress").css("right", (calc + "%"));
        }
        this.setState({[e.target.name]: e.target.value})
    }
    render() {
        let min_ = this.state.min;
        let max_ = this.state.max;
        if(this.props.range_type === "percentage"){
            min_ = (min_ * 100).toFixed(2) + "%";
            max_ = (max_ * 100).toFixed(2) + "%";
        };
        if(this.props.range_type === "dollar"){
            min_ = readableDollarValue(min_);
            max_ = readableDollarValue(max_ );
        };
        return (
            <div className={"RangeSlider " + this.props.name} data-value={[this.state.min, this.state.max]} onChange={(e)=>{this.props.onChange([$("." + this.props.name + " .range-min").val(), $("." + this.props.name + " .range-max").val()])}} style={{width: this.props.width}}>
                <div className="label">{this.props.label} <span>{min_} - {max_}</span></div>
                <div class="slider">
                    <div class="progress"></div>
                </div>
                <div class="range-input">
                    <input type="range" class="range-min" min={this.props.min} max={this.props.max} step={this.props.step} name="min" value={this.state.min} onChange={(e)=>{this.getInput(e); console.log(e)}} />
                    <input type="range" class="range-max" min={this.props.min} max={this.props.max} step={this.props.step} value={this.state.max} name="max" onChange={(e)=>this.getInput(e)} />
                </div>
            </div>
        )
    }
}    