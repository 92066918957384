import React, { Component } from 'react';
import axios from 'axios';
import PageHeaderDesktop from './PageHeaderDesktop.js';
import PageHeaderMobile from './PageHeaderMobile.js';
import { getCookie } from '../functions/functions.js';
import parse from 'html-react-parser';
import Footer from './Footer.js';
import $ from 'jquery';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');
const page_info_all = require('../' + folder._ + '/Data/contact.json');

let page_info;
let lang = getCookie("lang");
if (lang && lang.length > 0) {
    localhostName.dm = localhostName.dn + lang;
    page_info = page_info_all[lang];
} else {
    page_info = page_info_all["EN"];
};

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {},
            id: "",
            send_successful: false,
            userInfo: this.props.userInfo
        };
    }
    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let id = urlParams.get('id');
        this.setState({ id: id });
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: "page", "page": window.location.pathname })
            .then(res => {
            })
            .catch((error) => {
            });
    }
    changeForm(name, e) {
        this.setState({ [name]: e.target.value });
    }
    sendContact(e) {
        e.preventDefault();
        let newContact = {
            email: this.state.email,
            company: this.state.company,
            name: this.state.name,
            message: this.state.desc
        };
        axios.post(localhostName.host + '/api/contacts/add', newContact)
            .then(res => {
                $(".overview").html("<h2>Thank you for your message.</h2><div>We will answer within 24 hours.</div>");
            });
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    render() {
        return (
            <div className={"items_page " + (isMobile ? "mobile" : "")}>
                {isMobile ?
                    <PageHeaderMobile key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                    :
                    <PageHeaderDesktop key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                }
                <div className="container order_finished contact">
                    <div className="overview">
                        <h2>{page_info.first_headline}</h2>
                        <div className="text">
                            {parse(page_info.text)}
                        </div>
                        <div>
                            <div class="form-group">
                                <label >{page_info.form.email}*</label>
                                <input class="form-control" placeholder="E-Mail" value={this.state.email} onChange={(e) => this.changeForm("email", e)}></input>
                            </div>
                            <div class="form-group">
                                <label >{page_info.form.company} (optional)</label>
                                <input class="form-control" placeholder="Company Name" value={this.state.company} onChange={(e) => this.changeForm("company", e)}></input>
                            </div>
                            <div class="form-group">
                                <label >{page_info.form.name} (optional)</label>
                                <input class="form-control" placeholder="Name" value={this.state.name} onChange={(e) => this.changeForm("name", e)}></input>
                            </div>
                            <div class="form-group">
                                <label>{page_info.form.message}*</label>
                                <textarea class="form-control" placeholder="Your message" value={this.state.desc} onChange={(e) => this.changeForm("desc", e)}></textarea>
                            </div>
                            <button type="mastermit" class="btn btn-primary" onClick={(e) => this.sendContact(e)}>Send</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}