import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';

export default class ModalBoot extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
        this.hideModal = this.hideModal.bind(this);
    }
    componentDidMount(){
        console.log(this.state.show);
        this.setState({
            show: this.props.show
          })
    }
    hideModal(){
        this.props.hide(this.props.content,false);
    }
    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.hideModal()} className={this.props.className}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>{this.props.children}</Modal.Body>
            </Modal>
        )
    }
}