import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import $ from 'jquery';
import ItemOverview from './ItemOverview';
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');

export default class BucketPlayedButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            clicked: false,
        };
    }
    componentDidMount() {
        $(".BucketButtonModal .btn-close").click(function () {
            $(".BucketButtonModal").removeClass("show");
        });
    }
    login(e) {
        e.preventDefault();
        this.setState({ clicked: true });
    }
    addCity(type, e) {
        let new_items = { items: [this.props.id] };
        if (type === "learned") {
            axios.post(localhostName.host + '/api/users/additem', new_items, { withCredentials: true })
                .then(res => {
                    this.props.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        } if (type === "learned_remove") {
            axios.post(localhostName.host + '/api/users/removeitem', new_items, { withCredentials: true })
                .then(res => {
                    this.props.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else if (type === "favourite") {
            axios.post(localhostName.host + '/api/users/addfavouriteitem', new_items, { withCredentials: true })
                .then(res => {
                    this.props.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else if (type === "favourite_remove") {
            axios.post(localhostName.host + '/api/users/removefavouriteitem', new_items, { withCredentials: true })
                .then(res => {
                    this.props.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    clickButton(type, e) {
        e.preventDefault();
        console.log(this.props.logged);
        if (this.props.logged) {
            this.addCity(type, e);
        } else {
            if (!$(e.target).closest(".BucketButtonModal #logreg-forms").length) {
                $(".BucketButtonModal").removeClass("show");
            };
            if ($(e.target).closest(".to_collect").length) {
                $(".BucketButtonModal").addClass("show");
                $(".must_logged").html("!You have to be logged in to add something to your collection.");

            };
            if ($(e.target).closest(".to_bucketlist").length) {
                $(".BucketButtonModal").addClass("show");
                $(".must_logged").html("!You have to be logged in to add something to your favourite list.");
            };
        }
    }
    render() {
        return (
            (!this.props.learned ?
                <div className="BucketCollectButton">
                    {!this.props.favourite ?
                    <div className="to_bucketlist" onClick={(e) => { this.clickButton("favourite", e) }}>+Top Favourite</div>
                    :
                    <div className="to_bucketlist bucked" onClick={(e) => { this.clickButton("favourite_remove", e) }}><i className="fa fa-check"></i>On Favourite List</div>
                    }
                </div> :
                <div className="BucketCollectButton played">
                    {!this.props.favourite ?
                    <div className="to_bucketlist" onClick={(e) => { this.clickButton("favourite", e) }}>+Top Favourite</div>
                    :
                    <div className="to_bucketlist bucked" onClick={(e) => { this.clickButton("favourite_remove", e) }}><i className="fa fa-check"></i>On Favourite List</div>
                    }
                </div>
            )
        )
    }
}    