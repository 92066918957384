import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter } from "react-router-dom";
import $ from 'jquery';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');
const page_info_all = require('../' + folder._ + '/Data/website_info.json');
const website_info_all = require('../' + folder._ + '/Data/website_info.json');
let website_info;
let page_info;
let lang = getCookie("lang");

export default class PageHeaderMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav_menu: false,
        };
        if (lang && lang.length > 0) {
            localhostName.dm = localhostName.dn + lang;
            page_info = page_info_all[lang];
            website_info = website_info_all[lang];
        } else {
            page_info = page_info_all["EN"];
            website_info = website_info_all["EN"];
        };
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    render() {
        return (
            <div className={"items_page_header mobile_extern_header" + (this.state.nav_menu ? " nav_menu_open" : "")} ><nav className="navbar navbar_mobile navbar-expand-lg navbar-light bg-light" >
                <button onClick={() => this.nav_menu()} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Link className="navbar-brand" to={website_info.brand_url}><div className="mob_title">{website_info.brand}</div></Link>
                <div className="navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        {website_info.more_nav ? <div className="techniques_nav">{website_info.more_nav}</div> : ""}
                        {(website_info.technique_pages || []).map(p => {
                            return (<a className="nav-item nav-link" href={p.url} unstable_viewTransition><img className="nav_icon" src={"/icons/" + p.icon} />{p.name}</a>)
                        })}
                        <hr className="nav_line"></hr>
                        <Link className="nav-item nav-link" to="/membership">{page_info.nav_names.membership}</Link>
                        <Link className="nav-item nav-link" to="/aboutus">{page_info.nav_names.about_us}</Link>
                        <Link className="nav-item nav-link" to="/contact">{page_info.nav_names.contact}</Link>
                        <Link className="nav-item nav-link" to="/terms-and-conditions">{page_info.nav_names.tc_cs}</Link>
                        <Link className="nav-item nav-link" to="/privacy-policy">{page_info.nav_names.privacy}</Link>
                        <hr className="nav_line"></hr>
                        <div class="nav_tab login join">
                            {this.props.logged ?
                                ""
                                :
                                <Link to="/join"><button >Join</button></Link>
                            }
                        </div>
                        <div class="nav_tab login">
                            {this.props.logged ?
                                <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.state.user.firstname} {this.state.user.lastname.charAt(0)}.</span></Link></div>
                                :
                                <button onClick={() => this.showFormButton("showLogin", true)}>Login</button>
                            }
                        </div>
                    </div>
                </div>
            </nav>
            </div>
        )
    }
}  