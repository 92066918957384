import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import ModalBoot from '../components/Modal.js';
import LoginModal from './LoginModal.js';
import RegisterBuy from './RegisterBuy.js';
import MembershipFAQ from './MembershipFAQ.js';
import MembershipTC from './MembershipTC.js';
import parse from 'html-react-parser';
import $ from 'jquery';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import folder from './folder.json';
const page_info_all = require('../' + folder._ + '/Data/join_page.json');
const contentAll = require('../' + folder._ + '/Data/content.json');
const contentAll_coming = require('../' + folder._ + '/Data/content_coming.json');
let content_list;
let content_coming_list;
let localhostName = require('../' + folder._ + '/config.json');
let lang = (getCookie("lang") || "EN");
let content = {};

function isDomain(str) {
    const domainRegex = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
    return domainRegex.test(str);
}

export default class BuyReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            artworks: [],
            devoPics: [],
            settings: { _id: "df" },
            userInfo: this.props.userInfo,
            selected_artwork: { interpretations: [], artwork_id: "", title: "" },
            register_email: this.getCookie("register_email"),
            report: {
                "price": 0,
                "title": "",
                "money_back": "",
                "sub_title": "",
                "benefits": [
                ],
                "content": [
                ]
            }
        };
        lang = (getCookie("lang") || "EN");
        content = page_info_all[lang];
        content_list = contentAll[lang];
        content_coming_list = contentAll_coming[lang];
        this.onChange = this.onChange.bind(this);
        this.showFormButton = this.showFormButton.bind(this);
        this.fetchEvents();
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    checkLogged() {
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    console.log("loged");
                    this.setState({ user: res.data.user, logged: true });
                    this.setState({ step_register: true });
                    if (res.data.user.basic_payment) {
                        window.location.replace("/");
                    };
                } else {
                    this.setState({ logged: false, user: {} });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        this.checkLogged();
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: "page", "page": window.location.pathname })
            .then(res => {
            })
            .catch((error) => {
            });
    }
    fetchEvents() {
        let url = new URL(window.location.href);
        let report = url.searchParams.get("d");
        let pathName = "MarketingEN/sub/Websites/Buy/" + report;
        if (report) {
            axios.get(localhostName.host + '/data_master/' + pathName)
                .then(res => {
                    let data = res.data;
                    if (data) {
                        this.setState({
                            report: data,
                            data_loaded: true
                        })
                    } else {
                        this.handleRetry();
                    }
                })
                .catch(error => {
                    console.error("Error fetching events:", error);
                    this.handleRetry();
                });
        }
    }
    handleRetry() {
        if (this.state.retry_count < this.state.max_retries) {
            this.setState(prevState => ({ retry_count: prevState.retry_count + 1 }), () => {
                setTimeout(() => this.fetchEvents(), 1000);
            });
        } else {
            this.setState({ data_loaded: true, data_error: true });
            console.log("Max retries reached. Could not load events.");
        }
    }
    proc_register() {
        let data;
        axios.post(localhostName.host + '/api/joinlist/', data)
            .then(res => {
                console.log(res);
                this.setState({ userlists: res.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        let report = this.state.report;
        return (
            <div className={"join_page" + (this.props.blocked ? " blocked" : "")}>
                {isMobile ? "" :
                    <div className="background_video">
                        <video autoPlay muted loop>
                            <source src={content.join_video} type="video/mp4" />
                        </video>
                    </div>}
                <div className="join_content">
                    <div className="content_boxes">
                        <div className="box box_left">
                            <div className="benefits">
                                {this.props.on_page ? "" : <div className="close_button" onClick={() => { this.changeState("show_filters", false) }}><Link to="/"><img className="icon" src="/icons/left-arrow.png" /></Link></div>}
                                {this.props.blocked ? <div className="limit_reach">!You've reached the free usage limit. Sign up to continue your {content.brand[lang]} exploration.</div> : ""}
                                <div className="join_headline"><img className="icon" src="/icons/report.png" />{parse(report.title || "")}</div>
                                <div className="price"><img className="icon" src="/icons/price_b.png" />Price ${report.price}</div>
                                {report.benefits.map(b => {
                                    return (
                                        <div className="benefit">
                                            <div className="desc">
                                                <img className="icon" src="/icons/checked.png" />{parse(b.text || "")}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{"Included Content:"}</strong>
                                        <ul>{report.content.map(c => {
                                            return (<li>{c}</li>)
                                        })}</ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box box_right">
                            <div className="brand_name join_brandname" onClick={() => this.showFormButton("membership_faq", true)}>{content.brand[lang]} </div>
                            <div className="member_text">{report.sub_title} <div className="money_back_join">{report.money_back} </div> <span className="lifetime_more_info" onClick={() => this.showFormButton("membership_faq", true)}>(<i className="fa fa-info-circle" aria-hidden="true"></i>{content.more_i[lang]})</span></div>

                            {/*<input id="register_email" onChange={(e) => this.onChange(e)} value={this.state.register_email} placeholder="Type your email..." />*/}
                            <RegisterBuy />
                        </div>
                    </div>
                </div>
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
                </ModalBoot >
                <ModalBoot show={this.state.membership_faq} hide={this.showFormButton} content={"membership_faq"} className="membership_terms_condition">
                    <MembershipFAQ />
                </ModalBoot >
                <ModalBoot show={this.state.membership_tc} hide={this.showFormButton} content={"membership_tc"} className="membership_faq">
                    <MembershipTC />
                </ModalBoot >
            </div>
        )
    }
}    