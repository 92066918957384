import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BucketButtonModal from './BucketButtonModal.js';
import PageHeaderDesktop from './PageHeaderDesktop.js';
import PageHeaderMobile from './PageHeaderMobile.js';
import { getCookie } from '../functions/functions.js';
import { dynamicSort } from '../functions/functions.js';
import Footer from './Footer.js';
import parse from 'html-react-parser';
import $ from 'jquery';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');
let lang = getCookie("lang");
if (lang && lang.length > 0) {
    localhostName.dm = localhostName.dn + lang;
};

function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}-${formattedMonth}-${year}`;
};

function mapObject(obj) {
    let html = "";
    Object.keys(obj).forEach(function (key, index) {
        html += `<span>${key + ": " + obj[key]}</span>`;
    });
    return parse(html);
};

function ensureObject(value) {
    return (typeof value === "object" ? value : {});
};

export default class PagetrackerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            weather_month: (new Date).getMonth() + 1,
            continent_filter: [],
            pop_filter: "",
            gol_cont_filter: "",
            surf_points: 0,
            search_city: "",
            sort_by: "",
            filter_country: "",
            not_rainy: false,
            sunbathing: false,
            data_loaded: false,
            filter: [],
            filter_values: {},
            tracks: [],
            list: [],
            slider_filter: {}
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let name = url.searchParams.get("c");
        axios.get(localhostName.host + '/api/pagetrackers/data/tzgh/' + name)
            .then(res => {
                console.log(res.data);
                this.setState({ tracks: res.data });
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get('/list.json')
            .then(res => {
                this.setState({ list: res.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    showFormButton(targetShow, change) {
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    renderTracks() {
        let tracks = this.state.tracks;
        tracks.sort(dynamicSort("-lastupdate"));
        let list = this.state.list;
        return tracks.map(track => {
            let date = (track.date ? new Date(track.date) : null);
            let lastupdate = (track.lastupdate ? new Date(track.lastupdate) : null);
            track.visited_items = ensureObject(track.visited_items);
            track.visited_tabs = ensureObject(track.visited_tabs);
            track.used_filter = ensureObject(track.used_filter);
            track.visited_pages = ensureObject(track.visited_pages);
            track.sites = ensureObject(track.sites);
            return (
                <div className="single_track">
                    <div>IP: {track.ip}</div>
                    <div>{lastupdate ? formatDate(lastupdate) : ""}</div>
                    {(track.visited_pages || {})["JoinPagePayment"] ? <div><strong>Payment Site Visited</strong></div> : ""}
                    {(track.visited_pages || {})["/join"] ? <div><strong>Join Page Visited</strong></div> : ""}
                    <div><span className="">{track.city}</span>,<span className="">{track.country}</span>, <span className="">{date ? formatDate(date) : ""}</span></div>
                    <div>First Visit: <span className="">{track.referrer}</span>, Referrer Website: <span className="">{track.referrer_origin}</span>,</div>
                    <div className="cities"><strong>Items:</strong> {mapObject((track.visited_items || ""))}</div>
                    <div className="cities"><strong>Tabs:</strong> {mapObject((track.visited_tabs || ""))} </div>
                    <div className="cities"><strong>Filter:</strong> {mapObject((track.used_filter || ""))}</div>
                    <div className="cities"><strong>Pages:</strong> {mapObject((track.visited_pages || ""))}</div>
                    <div className="cities"><strong>Sites:</strong> {mapObject((track.sites || ""))}</div>
                </div>
            )
        })
    }
    render() {
        return (
            <div className={"membership_page cities_page " + (isMobile ? "mobile" : "")}>
                {isMobile ?
                    <PageHeaderMobile key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                    :
                    <PageHeaderDesktop key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                }
                <div className="page_content container">
                    <h1>Tracks</h1>
                    <div className="tracks">
                        {this.renderTracks()}
                    </div>
                </div>
                <Footer />
            </div>
        )

    }
}    