import React, {Component} from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import $ from 'jquery';
import ItemOverview from './ItemOverview';
import LoginModal from './LoginModal';

export default class BucketButtonModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            clicked: false,
        };
    }
    componentDidMount() {
        $(".BucketButtonModal .btn-close").click(function(){
            $(".BucketButtonModal").removeClass("show");
        });
        $(".BucketButtonModal").click(function(e) {
            var container = $(".BucketButtonModal #logreg-forms");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".BucketButtonModal").removeClass("show"); 
            }
          });    
    }
    login(e){
        e.preventDefault();
        this.setState({clicked: true});
    }
    render() {
        return (
            <div className="BucketButtonModal" tabindex="-2">
                <LoginModal checkLogged={this.props.checkLogged} hide={this.props.showFormButton} />
            </div> 
        )
    }
}    