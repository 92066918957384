import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BucketButtonModal from './BucketButtonModal.js';
import PageHeaderDesktop from './PageHeaderDesktop.js';
import PageHeaderMobile from './PageHeaderMobile.js';
import { getCookie } from '../functions/functions.js';
import parse from 'html-react-parser';
import Footer from './Footer.js';
import $ from 'jquery';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');
const page_info_all = require('../' + folder._ + '/Data/about_us.json');
let page_info;
let lang = (getCookie("lang") || "EN");


export default class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            items_list: [],
            items_filter: [],
            devoPics: [],
            settings: { _id: "df" },
            userInfo: this.props.userInfo,
            selected_item: {},
            show_filters: false,
            weather_filter: "",
            weather_month: (new Date).getMonth() + 1,
            continent_filter: [],
            pop_filter: "",
            gol_cont_filter: "",
            surf_points: 0,
            search_item: "",
            sort_by: "",
            filter_country: "",
            not_rainy: false,
            sunbathing: false,
            data_loaded: false,
            filter: [],
            filter_values: {},
            slider_filter: {}
        };
        if (lang && lang.length > 0) {
            localhostName.dm = localhostName.dn + lang;
            page_info = page_info_all[lang];
        } else {
            page_info = page_info_all["EN"];
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.onChange = this.onChange.bind(this);
        console.log(page_info);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    componentDidMount() {
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: "page", "page": window.location.pathname })
            .then(res => {
            })
            .catch((error) => {
            });
    }
    showFormButton(targetShow, change) {
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    render() {
        let filter = this.state.filter;
        let check_filter = function (name) {
            let filter_index = filter.findIndex((filter) => filter.name == name);
            return ((filter[filter_index] || { value: false }).value ? "selected" : "not_selected");
        };
        let filter_value = function (name) {
            let filter_index = filter.findIndex((filter) => filter.name == name);
            return (filter[filter_index] || { value: false }).value;
        };
        let countries = {
            UK: "United Kingdom", DE: "Germany", ES: "Spain", PT: "Portugal", FR: "France", IE: "Ireland", IT: "Italy", TR: "Turkey", NL: "Netherlands",
            US: "USA", MX: "Mexico", CA: "Canada", AU: "Australia", NZ: "New Zealand", JP: "Japan", CR: "Costa Rica", DO: "Dominican Republic", TH: "Thailand", CO: "Colombia", KR: "South Korea", VN: "Vietnam", BR: "Brazil", AR: "Argentina", CH: "Switzerland", AT: "Austria", ZA: "South Africa", JM: "Jamaica", IS: "Iceland", SE: "Sweden", TW: "Taiwan", NO: "Norway", IN: "New Delhi", BS: "Bahamas", KE: "Kenya", MU: "Mauritius", PHL: "Philippines", PE: "Peru", MY: "Malaysia", AE: "United Arab Emirates", CN: "China", BE: "Belgium", HU: "Hungary", GR: "Greece", GI: "Gibraltar", CZ: "Czech Republic", DK: "Denmark", HK: "Hong Kong", SG: "Singapore", ID: "Indonesia", NP: "Nepal", QA: "Qatar", MT: "Malta", MC: "Monaco", KY: "Cayman Islands", CL: "Chile", NA: "Namibia", GH: "Ghana"
        };
        let countries_names = {};
        Object.keys(countries).map((key, index) => {
            countries_names[countries[key]] = key;
        });
        countries_names = Object.keys(countries_names).sort().reduce(
            (obj, key) => {
                obj[key] = countries_names[key];
                return obj;
            },
            {}
        );
        console.log(page_info);
        return (
            <div className={"membership_page aboutus_page items_page " + (isMobile ? "mobile" : "")}>
                {isMobile ?
                    <PageHeaderMobile key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                    :
                    <PageHeaderDesktop key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                }
                <div className="page_content container">
                    <h1>{parse(page_info.first_headline)}</h1>
                    <div>
                        <div className="aboutus_box">
                            <div className="aboutus_text">
                                {parse(page_info.text)}
                            </div>
                            <div className="bottom_register header_register_box">
                            <div className="box">
                                <div className="member_text reg_title">{page_info.page_name} Member</div>
                                <div className="member_text">{page_info.membership_join.title}
                                    <div className="master_headline">
                                        <div>{page_info.membership_join.desc1}</div>
                                        <div>{page_info.membership_join.desc2}</div>
                                    </div>
                                    <span onClick={() => { $('#MembershipFAQ_modal').addClass('show') }}>{page_info.membership_join.more}</span></div>
                                {/*<input type="email" id="register_email" onChange={(e) => {this.onChange(e);this.setCookie("register_email",e.target.value,1)}} value={this.state.register_email} placeholder="Type your email..." />*/}
                                <Link to="/join" state={{ register_email: "this.state.register_email" }}><button>{page_info.membership_join.proceed}</button></Link>
                                <div className="already_login" >{page_info.membership_join.already} <span onClick={() => $('#Login_modal').addClass('show')}>Log in</span></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BucketButtonModal />
                <Footer />
            </div>
        )

    }
}    