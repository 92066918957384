import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { setCookie, getCookie, delete_cookie } from '../../functions/functions.js';
import parse from 'html-react-parser';
import $ from 'jquery';
import folder from '../folder.json';
let localhostName = require('../../' + folder._ + '/config.json');
const master_path = require("../../" + folder._ + "/Data/master_paths.json");
const main_tab = require("../../" + folder._ + "/Data/main_tab.json");
const tab_banner = require("../../" + folder._ + "/Data/tab_banner.json");
let master_name;
let lang;
let firstvisit = (getCookie("firstvisit") || "true");

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error occurred in ErrorBoundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default class Overview extends Component {
    constructor(props) {
        super(props);
        lang = getCookie("lang");
        firstvisit = (getCookie("firstvisit") || "true");
        master_name = window.location.pathname.split("/")[2];
        let url = new URL(window.location.href);
        let id = url.searchParams.get("id");
        let user_data = this.props.user_data;
        let learn_status;
        if (this.props.logged) {
            learn_status = (user_data.learn_status || {});
        } else {
            learn_status = (JSON.parse(getCookie("learn_status")) || {});
        };
        let item_name = (master_name ? master_path[master_name] : localhostName.dn);
        let learn_item = (learn_status[item_name] || []);
        let item_index = learn_item.findIndex(c => c.id === id);
        let learn_tabs = (learn_item[item_index] || {}).tabs;
        this.state = {
            language: "de",
            selectedCity: {
                name: "Default"
            },
            Scores: [],
            settings: { _id: "df" },
            userInfo: this.props.userInfo,
            check_exist: { Shops: false, },
            overview_tab: this.props.item_overview.mainTab || "overview",
            item_overview: this.props.item_overview,
            banner: (tab_banner[window.location.pathname.split("/")[2]] || "/artofwar_banner.jpg"),
            user_data: (user_data || { infos: {} }),
            tab_: {},
            id: "",
            logged: false,
            master_name: window.location.pathname.split("/")[2],
            item: {},
            learn_status: (learn_status || {}),
            learn_tabs: (learn_tabs || [])
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.showFormButtonItem = this.showFormButtonItem.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.reload = this.reload.bind(this);
    }
    checkLogged() {
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    this.setState({ user: res.data.user, logged: true });
                } else {
                    this.setState({ logged: false, user: {} });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let selected_tab = url.searchParams.get("tab") || this.state.overview_tab;
        let id = url.searchParams.get("id");
        this.initializeComponent();
        $(document).ready(function () {
            var element = $(".info_tabs");
            $(".scroll_right").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition + 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".item_scores_info").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".item_scores_info").removeClass("hide_left");
                };
            });
            $(".scroll_left").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition - 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if (newPosition <= 0) {
                    $(".item_scores_info").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".item_scores_info").removeClass("hide_right");
                };
            });
            $(".info_tabs").scroll(function () {
                var newPosition = element.scrollLeft();
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".item_scores_info").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".item_scores_info").removeClass("hide_left");
                };
                if (newPosition <= 0) {
                    $(".item_scores_info").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".item_scores_info").removeClass("hide_right");
                };
            });
            window.history.pushState(null, null, window.location.href);
        });
        this.checkLogged();
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: "item", item: id })
            .then(res => {
            })
            .catch((error) => {
            });
        $(".modal_ItemOverview").attr("tabindex", "none");
        if (firstvisit == "true") {
            setTimeout(function () {
                $(".overviewtab_info").removeClass("true");
            }, 7000);
            setCookie("firstvisit", "false", 200);
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.item_overview !== prevProps.item_overview) {
            this.initializeComponent(); 
        }
        if (this.props.user_data !== prevProps.user_data) {
            this.setState({ user_data: this.props.user_data });
        }
    }
    initializeComponent() {
        let url = new URL(window.location.href);
        let selected_tab = url.searchParams.get("tab") || this.state.overview_tab;
        let id = url.searchParams.get("id");
        let item = this.props.items_list.find(i => i.id === id);

        let all_tabs = (this.props.item_overview?.tabs || []).concat([{
            name: "notepad",
            title: "Notepad",
            data_folder: "Notepad",
            desc: "You can write your personal notes for this technique here. Your notes are private, only you can see it.",
            icon: "bell.png",
            type: ""
        }]);
    
        let tab_ = all_tabs.find(t => t.data_folder === selected_tab);
    
        this.setState({ 
            overview_tab: selected_tab, 
            id, 
            item, 
            tab_,
            item_overview: this.props.item_overview 
        });
    
        this.renderTab_Content(selected_tab);
        this.setState({ selectedCity: this.props.selected_item });
    
        axios.post(this.props.localhostName.host + '/api/pagetrackers/add', { type: "item", item: id })
            .then(res => {})
            .catch((error) => {});
    
        $(".modal_ItemOverview").attr("tabindex", "none");
    
        if (firstvisit === "true") {
            setTimeout(function () {
                $(".overviewtab_info").removeClass("true");
            }, 7000);
            setCookie("firstvisit", "false", 200);
        }
    }
    reload() {
        axios.get(localhostName.host + '/api/users/userdata', { withCredentials: true })
            .then(res => {
                let user_data = res.data.user_data;
                user_data.infos = (user_data.infos || { infos: {} })
                this.setState({ user_data: user_data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    showFormButtonItem(targetShow, change, parent) {
        this.setState({ [targetShow]: change, parent: parent });
    }
    changeTab(tab) {
        this.setState({ overview_tab: tab.name, tab_text: tab.desc, tab_: tab });
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        params.delete('tab');
        params.append('tab', tab.name);
        window.history.replaceState(null, null, window.location.pathname + '?' + params.toString());
        let value = tab.title;
        this.renderTab_Content((tab.name && tab.name));
        var container = document.querySelector('.info_tabs');
        var tabElement = document.getElementById(tab.data_folder);
        container.scrollLeft = tabElement.offsetLeft - (container.offsetWidth - tabElement.offsetWidth) / 2;
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: "tab", tab: value })
            .then(res => {
            })
            .catch((error) => {
            });
    }
    renderTabs() {
        let tabs_i = (this.props.item_overview.tabs || []);
        let overview_tab = this.state.overview_tab;
        let note_tab = [{
            "name": "notepad",
            "title": "Notepad",
            "data_folder": "Notepad",
            "desc": "You can write your personal notes for this technique here. Your notes are private, only you can see it.",
            "icon": "bell.png",
            "type": ""
        }];
        let tabs = tabs_i.concat(note_tab);
        return tabs.map(tab => {
            let done = this.state.learn_tabs.includes(tab.data_folder);
            return (
                <div id={tab.name} className={((overview_tab == tab.name ? "selected " : "") + "info_tab ") + (done ? "done" : "")} onClick={() => { this.changeTab(tab) }}><div className="image"><img class="icon" src={"/icons/" + tab.icon} /></div><div className="tab_text"><img class="icon done_image" src={"/icons/checked.png"} /><div>{tab.title}</div></div></div>
            )
        })
    }
    async renderTab_Content(tab) {
        let tabs_i = (this.props.item_overview.tabs || []);
        let tab_name = (tabs_i.find(c => c.name === tab) || {}).tab_name;
        try {
            const module = await import(`./${tab_name}.js`);
            const Tab_Content = module.default;
            this.setState({ Tab_Content });
        } catch (error) {
            console.error(`Error loading tab content for ${tab_name}:`, error);
            this.setState({ Tab_Content: null });
        }
    }
    render() {
        let overview_tab = this.state.overview_tab;
        let tabs = (this.props.item_overview.tabs || []);
        let tab_index = tabs.findIndex(c => c.name === overview_tab);
        let tabs_i = (this.props.item_overview.tabs || []);
        let tab_ = (tabs_i.find(c => c.name === overview_tab) || {});
        let tracker = this.props.tracker;
        let Tab_Content = this.state.Tab_Content;
        let id = this.state.id;
        let text = {
            "EN": {
                completed: "Completed",
                "many_tabs": "Many Tabs",
                "change_tabs": "Change tabs by clicking on them."
            },
            "DE": {
                completed: "Erledigt",
                "many_tabs": "Viele Tabs",
                "change_tabs": "Wechseln Sie Tabs, indem Sie darauf klicken."
            }
        };
        return (
            <div className={"itemOverview " + master_name}>
                <div className={"item_scores_modal "} >
                    <div className={"overviewtab_info " + firstvisit}>
                        <img src="/overviewtab_info.png" />
                        <div className={lang}>
                            <span>{text[lang].many_tabs}</span><span className="close" onClick={() => $(".overviewtab_info").removeClass("true")}>X</span><br /> {text[lang].change_tabs}
                        </div>
                    </div>
                    <div className="item_scores_info hide_left">
                        <div className="info_tab scroll_b scroll_left"><img class="icon" src="/icons/scroll.png" /></div>
                        <div className="info_tabs" onClick={(e) => tracker("tab", e.target.innerText)}>
                            {this.renderTabs()}
                        </div>
                        <div className="info_tab scroll_b scroll_right"><img class="icon" src="/icons/scroll.png" /></div>
                    </div>
                    <ErrorBoundary>
                        {Tab_Content && <Tab_Content key={this.state.overview_tab} id={id} localhostName={this.state.master_name ? (localhostName.dm + "/sub/" + master_path[this.state.master_name]) : localhostName.dm} tab_text={tab_.desc} type_path={tab_.data_folder} title={tab_.title} extra={tab_.extra} complete_blur={tab_.complete_blur} data={this.props.data} path={tab_.data_folder} blur={tab_.blur} logged={this.props.logged} />}
                    </ErrorBoundary>
                    <div className="overview_next_row overview_box"><div className="box">{tab_index > 0 ? <div className="back" onClick={() => { this.changeTab(tabs[tab_index - 1]) }}>Back</div> : ""}<div className="next" onClick={() => { this.changeTab(tabs[tab_index + 1]) }}>Next</div></div></div>
                </div>
            </div>
        )
    }
}    