import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter } from "react-router-dom";

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }
    updateContents(){  
    }
    render() {
        return (
            <footer>
                <div className="container">
                    <ul>
                        <li class="nav-item">
                            <Link class="nav-link" to="/contact">Contact</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/shipping">FAQ</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/terms-and-conditions">Ts&Cs</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/privacy-policy">Privacy & Cookies</Link>
                        </li>
                    </ul>
                    <p class="copyright"></p>
                </div>
          </footer>        
        )
    }
}    