import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');

export default class LoginModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            clicked: false,
            step: "login",
            login_failed: false,
            email_not_found: false
        };
        this.setState = this.setState.bind(this);
    }
    componentDidMount() {
        $(".BucketButtonModal .btn-close").click(function(){
            $(".BucketButtonModal").removeClass("show");
        })
    }
    onChange = e => {
        if ([e.target.id] === "step") {
            this.setState({ email_not_found: false, login_failed: false });
        };
        this.setState({ [e.target.id]: e.target.value });
    }
    login(e) {
        e.preventDefault();
        let user = {
            email: this.state.email,
            password: this.state.password
        };
        var self = this;
        if (user.email.length > 0 && user.password.length > 0) {
            axios.post(localhostName.host + '/api/users/login', user, { withCredentials: true })
                .then(res => {
                    if (res.data._id) {
                        window.location.replace("/");
                    };
                })
                .catch(function (error) {
                    if (error.response.data === "Unauthorized") {
                        self.setState({ login_failed: true });
                    };
                });
        };
    }
    passwordforgot(e) {
        e.preventDefault();
        let user = {
            email: this.state.email
        };
        var self = this;
        if (user.email.length > 0) {
            axios.post(localhostName.host + '/api/users/passwordforgot', user)
                .then(res => {
                    self.setState({ step: "thank_recover" });
                })
                .catch((error) => {
                    if (error.response.data === "email not found") {
                        self.setState({ email_not_found: true });
                    };
                });
        };
    }
    render() {
        return (
            <div id="logreg-forms">
                <div className="bucket_modal btn-close"></div>
                {this.state.step === "login" ?
                    <form className="form-signin">
                        <div className="must_logged"></div>
                        <h1 > Login</h1>
                        <div className="log_input email">
                            <input className="form-control" type="email" _ref="email" placeholder="E-Mail" id="email" onChange={(e) => this.onChange(e)} value={this.state.email} required autofocus />
                        </div>
                        <div className="log_input pass">
                            <input className="form-control" type="password" _ref="password" placeholder="Password" id="password" onChange={(e) => this.onChange(e)} value={this.state.password} required />
                        </div>
                        <button className="btn btn-success btn-block" type="mastermit" onClick={(e) => this.login(e)} ><i className="fas fa-sign-in-alt"></i> Login</button>
                        {this.state.login_failed ? <p className="login_error">!Login failed. Wrong email or password.</p> : ""}
                        <div className="forgot_back" onClick={() => this.onChange({ target: { id: "step", value: "password_forgot" } })}><i class="fa fa-key" aria-hidden="true"></i>Forgot password?</div>
                        <hr />
                        <p className="dont_have">Don't have an account!</p>
                        <a href="/join"><button className="btn btn-primary btn-block" type="button" id="btn-signup"><i className="fas fa-user-plus"></i> Register New Account</button></a>
                    </form>
                    :
                    ""
                }
                {this.state.step === "password_forgot" ?
                    <form className="form-signin">
                        <div className="must_logged"></div>
                        <h1 > Recover Password</h1>
                        <div className="log_input email">
                            <input className="form-control" type="email" _ref="email" placeholder="E-Mail" id="email" onChange={(e) => this.onChange(e)} value={this.state.email} required autofocus />
                        </div>
                        <button className="btn btn-success btn-block" type="mastermit" onClick={(e) => this.passwordforgot(e)} ><i className="fas fa-sign-in-alt"></i> Send Recover E-Mail</button>
                        {this.state.email_not_found ? <p className="login_error">!Email not found.</p> : ""}
                        <div className="forgot_back" onClick={() => this.onChange({ target: { id: "step", value: "login" } })}><i class="fa fa-arrow-left" aria-hidden="true"></i>Back to login</div>
                        <hr />
                        <p>Don't have an account!</p>
                        <a href="/join"><button className="btn btn-primary btn-block" type="button" id="btn-signup"><i className="fas fa-user-plus"></i> Register New Account</button></a>
                    </form>
                    :
                    ""
                }
                {this.state.step === "thank_recover" ?
                    <form className="form-signin">
                        <div className="must_logged"></div>
                        <h1 > Recover Password</h1>
                        <div className="thank_recover">Thank you. An email has been sent to change your password.</div>
                    </form>
                    :
                    ""
                }
            </div>
        )
    }
}    