import React, { Component } from 'react';

//import CompanyCulture from './CompanyCulture/Routes';
import System from './System/Routes';
//import Test from './Test/Routes';
//import Scieart from './Scieart/Routes';
//import ArtWar from './ArtWar/Routes';

export default class App extends Component {
  constructor() {
    super();
    this.state = {
    };
  }
  render() {
    return (
      <System />
    )
  }
}

