import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ModalBoot from '../../components/Modal.js';
import BucketButtonModal from '../BucketButtonModal.js';
import ItemOverview from '../ItemOverview.js';
import LoadingSVG from '../LoadingSVG.js';
import LoginModal from '../LoginModal.js';
import MembershipFAQ from '../MembershipFAQ.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-tooltip/dist/react-tooltip.css';
import { list_filter } from '../List/functions.js';
import Filter from '../List/Filter_Master.js';
import { setCookie, getCookie, delete_cookie } from '../../functions/functions.js';
import $ from 'jquery';
import parse from 'html-react-parser';
import Article from '../Article.js';
import folder from '../folder.json';
let localhostName = require('../../' + folder._ + '/config.json');
const website_info_all = require('../../' + folder._ + '/Data/website_info.json');
const page_info_all = require('../../' + folder._ + '/Masters/Data/page_info_all.json');
const item_overview_all = require('../../' + folder._ + '/Masters/Data/item_overview_all.json');
const filter_info_all = require('../../' + folder._ + '/Masters/Data/filter_info.json');
const master_path = require('../../' + folder._ + '/Data/master_paths.json');
let filter_info;
let page_data;
let page_info;
let item_overview;
let website_info;
let lang;
let first_m = (getCookie("first_m") || "true");
let master_name;


function findFilterByName(data, filterName) {
    for (const item of data) {
        if (item.filters) {
            const found = findFilterByName(item.filters, filterName);
            if (found) return found;
        }
        if (item.name === filterName) {
            return item;
        }
    }
    return null;
};

export default class MasterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            items_filter: [],
            userInfo: this.props.userInfo,
            selected_item: {},
            show_filters: true,
            weather_filter: "",
            weather_month: (new Date).getMonth() + 1,
            continent_filter: [],
            pop_filter: "",
            gol_cont_filter: "",
            search_item: "",
            sort_by: "",
            filter_country: "",
            not_rainy: false,
            sunbathing: false,
            data_loaded: false,
            filter: [],
            filter_values: {},
            slider_filter: {},
            logged: (this.props.logged || false),
            tracker: {
                items: 0,
                tabs: 0,
                filter: 0,
                cities: 0
            },
            tracker_daily: {
                items: 0,
                tabs: 0,
                filter: 0,
                cities: 0
            },
            items_filters: [],
            tracker_stop: false,
            tracker_stop_daily: false,
            block: false,
            user: (this.props.user ? this.props.user : {}),
            user_data: (this.props.user ? this.props.user.user_data : {}),
            list: [],
            master_name: window.location.pathname.split("/")[2],
            learn_items: [],
            view_style: "Box"
        };
        lang = (getCookie("lang") || "EN");
        master_name = window.location.pathname.split("/")[2];
        if (master_name) {
            page_data = page_info_all[master_name]["page_data"];
            if (lang && lang.length > 0) {
                localhostName.dm = localhostName.dn + lang;
                page_info = page_info_all[master_name][lang];
                filter_info = filter_info_all[master_name][lang];
                item_overview = item_overview_all[master_name][lang];
                website_info = website_info_all[lang];
            } else {
                page_info = page_info_all[master_name]["EN"];
                filter_info = filter_info_all[master_name]["EN"];
                item_overview = item_overview_all[master_name]["EN"];
                website_info = website_info_all["EN"];
            };
            this.state.sort_by = page_data.sort;
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.showFormButtonItem = this.showFormButtonItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.changeState = this.changeState.bind(this);
        this.onChange = this.onChange.bind(this);
        this.tracker = this.tracker.bind(this);
        this.setState = this.setState.bind(this);
        this.updateLearnStatus = this.updateLearnStatus.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    notLoggedModal(type, text) {
        if (type === "filter") {
            text = "<span>!</span>You have to be logged in to use this filter."
        };
        if (this.state.logged) {
        } else {
            $(".BucketButtonModal").addClass("show");
            $(".must_logged").html(text);
        };
    }
    tracker(type, value) {
        let tracker = (this.state.tracker || {
            items: 0,
            tabs: 0,
            filter: 0,
            cities: 0
        });
        let tracker_daily = (this.state.tracker_daily || {
            items: 0,
            tabs: 0,
            filter: 0,
            cities: 0
        });
        let tracker_stop = this.state.tracker_stop;
        let tracker_stop_daily = this.state.tracker_stop_daily;
        if (type === "item") {
            tracker.items = tracker.items + 1;
            tracker_daily.items = tracker_daily.items + 1;
        };
        if (type === "tab") {
            tracker.tabs = tracker.tabs + 1;
            tracker_daily.tabs = tracker_daily.tabs + 1;
        };
        if (type === "filter") {
            tracker.filter = tracker.filter + 1;
            tracker_daily.filter = tracker_daily.filter + 1;
        };
        this.setState({ tracker, tracker_daily });
        if (tracker.cities > 8 && tracker.tabs > 3 && tracker.filter > 8 && !this.state.logged) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.cities > 14 && tracker.tabs > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.items > 10 && tracker.tabs > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.cities > 30) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.items > 20) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.cities > 7 && tracker.items > 7 && tracker.tabs > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.cities > 20 && tracker.filter > 8 && !this.state.logged) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker_daily.cities > 1 || tracker_daily.items > 1 && tracker_daily.tabs > 1 && !this.state.logged) {
            this.setState({ tracker_stop_daily: true });
            setCookie("tracker_stop_daily", true, 1);
            this.props.refresh();
        };
        setCookie("tracker", JSON.stringify(tracker), 365);
        setCookie("tracker_daily", JSON.stringify(tracker_daily), 1);
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let selected_tab = url.searchParams.get("tab");
        let id = url.searchParams.get("id");
        let pay_suc = url.searchParams.get("success");
        let position = url.searchParams.get("s");
        if (position === "top") {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };
        if (pay_suc === "basic") {
            this.showFormButton("showPaySuc", true);
        };
        let tracker = getCookie("tracker");
        let tracker_stop = getCookie("tracker_stop");
        let tracker_stop_daily = getCookie("tracker_stop_daily");
        this.setState({ tracker_stop, tracker_stop_daily, tracker: JSON.parse(tracker) });
        if (page_info.close_filters) {
            this.setState({ show_filters: false });
        };
        this.updateLearnStatus();
        $(document).click(function (event) {
            var $target = $(event.target);
            if (!$target.closest('.navbar_box').length) {
                if (!$target.closest('.header_navbar .more').length) {
                    $('.navbar_box').removeClass("show_navbar");
                } else {
                    if ($('.navbar_box.show_navbar').length > 0) {
                        $('.navbar_box').removeClass("show_navbar");
                    } else {
                        $('.navbar_box').addClass("show_navbar");
                    };
                }
            }
        });
        $(window).scroll(function () {
            if ($(window).scrollTop() > ($(".viewTrap").offset().top)) {
                $(".page_content").addClass("viewActive");
            } else if ($(window).scrollTop() < ($(".viewTrap").offset().top)) {
                $(".page_content").removeClass("viewActive");
            };
        });
        axios.get(localhostName.host + '/data_master/' + localhostName.dm + '/' + page_data.data + '/list')
            .then(res => {
                this.setState({ data_loaded: true, list: res.data });
                if (id && id.length > 2) {
                    let selected_item = res.data.find(r => r.id === id);
                    this.setState({ selected_item });
                    this.showFormButton("showItemOverview", true, id, selected_tab);
                };
            })
            .catch(function (error) {
                console.log(error);
            });
        this.handlePopState = () => {
            window.location.href = '/';
        };
        window.addEventListener('popstate', this.handlePopState);
        setCookie("content", window.location.pathname, 100);
        if (first_m == "true") {
            $('html, body').scrollTop(0);
            setTimeout(function () {
                $(".overviewtab_info").removeClass("true");
            }, 8000);
            setCookie("first_m", "false", 200);
        };
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: "page", page: master_name })
            .then(res => {
            })
            .catch((error) => {
            });
    }
    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({ user: this.props.user, user_data: this.props.user.user_data })
        }
    }
    showFormButton(targetShow, change, id, tab) {
        if (this.state.logged && !this.props.basic_payment) {
            window.location.replace("/join?basic_payment=not-completed");
            return;
        };
        if (targetShow === "showItemOverview") {
            if (!this.props.blocked || this.props.basic_payment) {
                this.setState({ [targetShow]: change });
            } else {
                window.location.replace("/join");
            };
        } else {
            this.setState({ [targetShow]: change });
        };
        if (targetShow === "showItemOverview" && change === false) {
            window.history.replaceState(null, null, window.location.pathname);
        };
        if (targetShow === "showItemOverview" && change === true) {
            window.history.replaceState(null, null, window.location.pathname + "?id=" + id + (tab ? ("&tab=" + tab) : ""));
        };
    }
    showFormButtonItem(targetShow, change, parent) {
        if (targetShow === "showItemOverview" && change == false) {
            this.props.refresh();
        };
        this.setState({ [targetShow]: change, parent: parent });
    }
    selectItem(item) {
        this.setState({ selected_item: item });
    }
    changeState(state, value) {
        this.setState({ [state]: value });
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    sort_by(value) {
        this.setState({ sort_by: value });
    }
    select_page(value) {
        window.location.href = value;
    }
    search_item(e) {
        this.setState({ search_item: e.target.value })
    }
    updateLearnStatus() {
        let learn_status;
        let user_data = this.props.user.user_data;
        if (this.props.logged) {
            learn_status = (user_data.learn_status || {});
        } else {
            learn_status = (JSON.parse(getCookie("learn_status")) || {});
        };
        let item_name = (this.state.master_name ? master_path[this.state.master_name] : localhostName.dn);
        let learn_items = (learn_status[item_name] || []);
        this.setState({ learn_items });
    }
    renderList() {
        let List = (this.state.list.length > 0 && list_filter(this.state) || []);
        let view_style = this.state.view_style;
        let score_rate = function (value) {
            let score_rate = (value / 5 * 100);
            return score_rate;
        };
        let counter = 0;
        let score_color = function (value) {
            let score_rate = (value / 5 * 100);
            if (score_rate > 85) {
                return "#2bde73";
            } else if (score_rate > 65) {
                return "#2bde73";
            } else if (score_rate > 45) {
                return "#ffc924";
            } else if (score_rate > 30) {
                return "#ff4742";
            }
            else {
                return "#ff4742";
            }
        };
        if (List.length < 1) {
            return (
                <h1 className="no_found">No List found.</h1>
            )
        };
        let learn_items = this.state.learn_items;
        if (view_style === "List") {
            let category = [];
            this.state.list.map(c => {
                if (!category.find(d => d.name === c.category)) {
                    let new_c = {
                        "name": c.category,
                        "parents": [{ name: c.parent, topics: [c] }]
                    };
                    category.push(new_c);
                } else {
                    let index = category.findIndex(d => d.name === c.category);
                    let parents = category[index].parents;
                    if (!parents.find(d => d.name === c.parent)) {
                        parents.push({ name: c.parent, topics: [c] });
                        category[index].parents = parents;
                    } else {
                        let index_t = parents.findIndex(d => d.name === c.parent);
                        parents[index_t].topics.push(c);
                        category[index].parents = parents;
                    };
                };
            });
            return (<div className="list_type_items">
                {category.map(c => {
                    return (
                        <div className="cat_box"><div className="cat_">{c.name}</div>
                            {c.parents.map(d => {
                                return (
                                    <div className="parent_box"><div className="par_">{d.name}</div>
                                        {d.topics.map(item => {
                                            counter += 1;
                                            let status = learn_items.find(c => c.id === item.id);
                                            let tabs_r = (status ? status.tabs.filter(c => c !== "Notepad") : []);
                                            let tabs_o = item_overview.tabs;
                                            let completed = Math.round(((tabs_r || []).length / (tabs_o || []).length).toFixed(2) * 100);
                                            completed = (completed >= 100 ? 100 : completed);
                                            return (<div className="topic" data-item={item.name} onClick={() => { this.showFormButton("showItemOverview", true, item.id); this.tracker("item", item.id); this.selectItem(item) }}>{item.single_name} <span className="completed">[{completed}%]</span></div>)
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    );
                })}
            </div>)
        } else {
            return List.map((item) => {
                counter += 1;
                let status = learn_items.find(c => c.id === item.id);
                let tabs_r = (status ? status.tabs.filter(c => c !== "Notepad") : []);
                let tabs_o = item_overview.tabs;
                let completed = Math.round(((tabs_r || []).length / (tabs_o || []).length).toFixed(2) * 100);
                completed = (completed >= 100 ? 100 : completed);
                let error_image = (page_info.default_thumb ? page_info.default_thumb : (`${localhostName.dn}/list_thumbs/${["99d8s602vr7", "7foeweymq2t", "ynd2ms84jw8", "ayoa9nivij", "lv7xlip7ng"][Math.floor(Math.random() * 5)]}.jpg`));
                let thumb_image = {
                    old_own: ('/' + localhostName.dn + '/' + master_name + '/list_thumbs/' + item.id + '.jpg'),
                    own_thumb: (localhostName.data_url + '/' + localhostName.dn + '/thumbs/' + item.id + '.jpg'),
                    city_thumb: (localhostName.data_url + '/cities_thumbs/' + item.name + '.jpg'),
                };
                return (
                    <div className={"item_box " + (completed == 100 ? "completed " : " ") + ("show_scores_" + (page_data.show_scores || page_data.show_info))} >
                        <div className="item_box_card" data-item={item.name} onClick={() => { this.showFormButton("showItemOverview", true, item.id); this.tracker("item", item.id); this.selectItem(item) }} >
                            <div className="overlay">
                                <LazyLoadImage
                                    threshold={800}
                                    onError={(e) => $(e.target).attr(`src`, `/${error_image}`)}
                                    src={thumb_image[page_data.thumb_url]} />
                            </div>
                            <div className="overlay"></div>
                            <div className={"top_row " + ("own_top_" + page_data.own_top)}>
                                <div className="rank">{counter}</div>
                                <div className="total_score"><i className="fa fa-cubes"></i> {completed}%</div>
                                {item.own_top ? parse(item.own_top) : ""}
                            </div>
                            <div className="item_location">
                                <div className="item_name">{item.name.includes("<div") ? parse(item.name) : (item.name.length > 140 ? (item.name.slice(0, 140) + " ...") : item.name)}</div>
                            </div>
                            <div className="item_card_bottom">
                                {/*<div className="item_population">
                                    1,5 Mio.
                                </div>*/}
                                {/*<div className="monthly_costs">
                                    {"$" + item["3nights_2person"]} <br /> <span>3 Nights 2 Persons</span>
                                </div>*/}
                            </div>
                            <div className={page_data.show_scores ? "item_scores" : "disable"}>
                                <div className="item_scores_inner">
                                    <div className="item_scores_bars">
                                        {page_info.main_scores.map(s => {
                                            return (
                                                <div className="single_score">
                                                    <p className="score_desc"><img className="image_icon" src={"/icons/" + s.icon} />{s.title}</p>
                                                    <div className="score_bar">
                                                        <div className="score_bar_scale" style={{ width: String(score_rate((item.main_scores || {})[s.name]) + "%"), background: score_color((item.main_scores || {})[s.name]) }}>{(item.main_scores || {})[s.name] > 5 ? 5.00 : Number((item.main_scores || {})[s.name] || "").toFixed(2)}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            {page_data.show_info ? <div className="item_scores thumb_html">
                                <div className="item_scores_inner">
                                    {parse(item.thumb_html)}
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                );
            });
        };
    }
    view_style(style) {
        this.setState({ view_style: style });
    }
    handleCheckboxChange(e) {
        this.setState({ show_completed: e.target.checked });
    }
    update_filter(value) {
        let filter = this.state.filter;
        let index = filter.findIndex((f) => f === value);
        if (index < 0) {
            filter.push(value);
        } else {
            filter = filter.filter(e => e !== value);
        };
        this.setState({ filter: filter });
    }
    render() {
        let page_count = Number(this.state.list.length) * item_overview.tabs.length;
        let page_c = 0;
        this.state.learn_items.map(c => {
            let tabs_c = c.tabs.filter(c => c !== "Notepad");
            page_c += tabs_c.length;
        });
        let text = {
            "EN": {
                completed: "Completed",
                "many_tabs": "Content",
                "change_tabs": "You can switch between the main content at any time."
            },
            "DE": {
                completed: "Erledigt",
                "many_tabs": "Inhalte",
                "change_tabs": "Sie können jederzeit zwischen den Hauptinhalten wechseln."
            }
        };
        return (
            <div className={"items_page desktop " + master_name + " " + page_data.page_css}>
                <div className="items_page_header">
                    <img src={localhostName.data_url + "/banners/" + page_data.banner} width="400" className="header_video" autoPlay muted loop>
                    </img>
                    <svg viewBox="0 0 1440 120" className="wave" ><path d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path></svg>
                    <div className={"header_navbar " + ("type_" + website_info.nav_type)}>
                        <Link to={website_info.brand_url}><div className="logo_name">{website_info.brand}</div></Link>
                        <div className="links_navbar">
                            {(website_info.technique_pages || []).map(p => {
                                return (<Link to={p.url}><div className="nav_tab nav_extra" >{p.name}</div></Link>)
                            })}
                            <Link to="/membership" className="t_1"><div className="nav_tab">{website_info.nav_names.membership}</div></Link>
                            <Link to="/faq"><div className="nav_tab betaND">FAQ</div></Link>
                            <a class="dropdown">
                                <div className="nav_tab">
                                    {website_info.more_nav}
                                </div>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <div className="techniques">
                                        {website_info.main_content ? <div className="techniques_nav">{website_info.main_nav}</div> : ""}
                                        {(website_info.main_content || []).map(p => {
                                            return (<div class="dropdown-item" onClick={() => { window.location.href = p.url; }} ><img className="nav_icon" src={"/icons/" + p.icon} />{p.name}</div>)
                                        })}
                                        {website_info.main_content ? <hr className="nav_line"></hr> : ""}
                                        <div className="techniques_nav">{website_info.more_nav}</div>
                                        {(website_info.technique_pages || []).map(p => {
                                            return (<div class="dropdown-item" onClick={() => { window.location.href = p.url; }} ><img className="nav_icon" src={"/icons/" + p.icon} /><span>{p.name}</span>
                                                <p>{p.desc}</p>
                                            </div>)
                                        })}
                                    </div>
                                    <div>
                                        <div class="nav_tab login join">
                                            {this.props.logged ?
                                                ""
                                                :
                                                <Link to="/join"><button >Join</button></Link>
                                            }
                                        </div>
                                        <a class="dropdown-item" href="/membership#faq"><img className="nav_icon" src="/icons/faq.png" />{website_info.nav_names.faq}</a>
                                        <Link class="dropdown-item" to="/aboutus"><img className="nav_icon" src="/icons/group.png" />{website_info.nav_names.about_us}</Link>
                                        <Link class="dropdown-item" to="/contact"><img className="nav_icon" src="/icons/letter.png" />{website_info.nav_names.contact}</Link>
                                        <Link class="dropdown-item" to="/terms-and-conditions"><img className="nav_icon" src="/icons/terms-and-conditions.png" />{website_info.nav_names.tc_cs}</Link>
                                        <Link class="dropdown-item" to="/privacy-policy"><img className="nav_icon" src="/icons/privacy-policy.png" />{website_info.nav_names.privacy}</Link>
                                    </div>
                                </div>
                            </a>
                            <div className="nav_lang">
                                {website_info.languages.length > 1 ? website_info.languages.map(l => {
                                    return (
                                        <div onClick={() => { setCookie("lang", l.code, 365); window.location.reload(); }}><img src={"/icons/" + l.icon} />{l.name}</div>
                                    )
                                }) : ""}
                            </div>
                        </div>
                        <div class="nav_tab login">
                            {this.state.logged ?
                                <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.state.user.firstname} {this.state.user.lastname.charAt(0)}.</span></Link></div>
                                :
                                <button onClick={() => this.showFormButton("showLogin", true)}>Login</button>
                            }
                        </div>
                    </div>
                    <div className="header_content">
                        <div className="header_title">
                            <div className="title_top">{page_info.frontpage_headline}</div>
                            <div className="title_sub">{parse(page_info.frontpage_masterheadline)}</div>
                            <div className="score_pages">
                                <div className="c_t">{text[lang].completed}</div>
                                <div className="outer">
                                    <div className="inner" style={{ width: ((page_c / page_count * 100) + "%"), background: "rgb(43, 222, 115)" }}></div>
                                    <div className="score_output"><span className="score_number">{page_c}/{page_count} Pages</span></div>
                                </div>
                            </div>
                        </div>
                        {this.state.logged ?
                            ""
                            :
                            <div className="header_register_box">
                                <div className="box">
                                    <div className="register_image">
                                        <img className="cover" src="/golf-front.jpg" />
                                        {/*<img className="play_button" src="https://www.freeiconspng.com/thumbs/play-button-icon-png/play-button-icon-png-0.png" />*/}
                                    </div>
                                    <div className="member_text"><span className="become_golftuser">Become a EasyDisciplineer</span><br />Lifetime membership now for just $99. <span onClick={() => this.showFormButton("membership_faq", true)}>+learn more</span></div>
                                    {/*<input type="email" id="register_email" onChange={(e) => {this.onChange(e);this.setCookie("register_email",e.target.value,1)}} value={this.state.register_email} placeholder="Type your email..." />*/}
                                    <Link to="/join" state={{ register_email: "this.state.register_email" }}><button>Proceed to registration</button></Link>
                                    <div className="already_login">Already a member? <span onClick={() => this.showFormButton("showLogin", true)}>Log in</span></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Article />
                <div className="page_content">
                    <div className="viewTrap"></div>
                    {this.state.show_filters ?
                        <Filter setState={this.setState} filter_info={filter_info} state={this.state} notLoggedModal={this.notLoggedModal} tracker={this.tracker} />
                        : ""}
                    <Container className={"container_items" + (!this.state.show_filters ? " none_filters" : "")} >
                        <div className="content_topbar">
                            <div className={"filter_button " + (page_info.no_filters ? "disable" : "")}>
                                {!this.state.show_filters ? <button className="show_filter" onClick={() => { this.changeState("show_filters", true) }}>Filters</button> : ""}
                                {this.state.show_filters ? <button className="hide_filter" onClick={() => { this.changeState("show_filters", false) }}>Close Filters</button> : ""}
                            </div>
                            <div className="sort_by select_page">
                                <div className="select_c">Selected Content</div>
                                <select value={window.location.pathname} onChange={(e) => this.select_page(e.target.value)}>
                                    {website_info.main_content ? <optgroup label="Main">
                                        {(website_info.main_content || []).map(p => {
                                            return (<option className="nav-item nav-link" value={p.url} >{p.name}</option>)
                                        })}
                                    </optgroup> : ""}
                                    <optgroup label="Techniques & more - Select">
                                        {(website_info.technique_pages || []).map(p => {
                                            return (<option className="nav-item nav-link" value={p.url} >{p.name}</option>)
                                        })}
                                    </optgroup>
                                </select>
                            </div>
                            <div className="filter_search">
                                <input placeholder={page_info.search_placeholder} onChange={(e) => { this.search_item(e) }} value={this.state.search_item} />
                                <div className="input_plus betaND">+</div>
                            </div>
                            <div className={"sort_by " + (page_info.no_sort ? "disable" : "")}>
                                <select value={this.state.sort_by} onChange={(e) => this.sort_by(e.target.value)}>
                                    <optgroup label="Scores">
                                        <option>Sort</option>
                                        {(page_info.sort || []).map(p => {
                                            return (<option value={p.name} >{p.title}</option>)
                                        })}
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div className="filter_overview">
                            {this.state.filter.map(f => {
                                let title = findFilterByName(filter_info,f).title;
                                return(
                                    <div className="single_filter"><i onClick={() => { this.update_filter(f) }} className="fa-solid fa-x"></i>{title}</div>
                                )
                            })}
                        </div>
                        <div className={page_data.no_learn_status ? "disable_learn" : (this.state.show_completed ? "" : "completed_off")}>
                            <div className="on_off">
                                <div className="on_switch">
                                    <label>
                                        <input type="checkbox" checked={this.state.show_completed ? true : false} onChange={e => this.handleCheckboxChange(e)} /><span class="slider round"></span>
                                    </label>
                                </div>
                                <div>Show also completed {page_info.item_name}</div>
                            </div>
                            {!this.state.logged ? <div className="more_info_text">To get more information about the {page_info.item_name}, click on it.</div> : ""}
                            {page_data.list_style && this.state.view_style === "Box" ? <div className="change_list_view" onClick={() => this.view_style("List")}><img src="/icons/list_view.png" />Change to List View for better Overview</div> : ""}
                            {page_data.list_style && this.state.view_style === "List" ? <div className="change_list_view" onClick={() => this.view_style("Box")}><img src="/icons/box.png" />Change to Box View</div> : ""}
                            <div className={"items_sorted " + (!this.state.data_loaded ? "data_loading" : "")}>
                                <div className="loading_svg">
                                    <LoadingSVG />
                                </div>
                                {this.renderList()}
                            </div>
                        </div>
                        <ModalBoot show={this.state.showItemOverview} hide={this.showFormButton} content={"showItemOverview"} className="modal_ItemOverview">
                            <ItemOverview key={this.state.list + this.state.user_data + this.state.selected_item} page_data={page_data} localhostName={localhostName} item_overview={item_overview} selected_item={this.state.selected_item} tracker={this.tracker} items_list={this.state.list} updateLearnStatus={this.updateLearnStatus} logged={this.state.logged} user_data={this.state.user_data} refresh={this.props.refresh} />
                        </ModalBoot >
                        <ModalBoot show={this.state.showPaySuc} hide={this.showFormButton} content={"showPaySuc"} className="modal_Login modal_success">
                            <h1>Congratulations!</h1>
                            <div>Your payment has been confirmed, and you are now a Basic {website_info.brand} Member for life. This entitles you to full and unrestricted access to all item data, 24 hours a day, 7 days a week.</div>
                            <button class="btn btn-success btn-block float-right" onClick={() => {
                                this.showFormButton("showPaySuc", false);
                                window.history.pushState({}, document.title, window.location.pathname);
                            }}> Thanks</button>
                        </ModalBoot >
                        <ModalBoot show={this.state.membership_faq} hide={this.showFormButton} content={"membership_faq"} className="membership_terms_condition">
                            <MembershipFAQ />
                        </ModalBoot >
                    </Container>
                </div>
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
                </ModalBoot >
                <BucketButtonModal checkLogged={this.checkLogged} hide={this.showFormButton} />
            </div>
        )

    }
}    