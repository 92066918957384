import React, {Component} from 'react';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');
const page_info_all = require('../' + folder._ + '/Data/membership_page.json');

let page_info;
let lang = getCookie("lang");
if(lang && lang.length > 0){
    localhostName.dm = localhostName.dn + lang;
    page_info = page_info_all[lang];
}else{
    page_info = page_info_all["EN"];
};

export default class MembershipFAQ extends Component {
    render() {
        return (
            <div className="membership_info">
                <div class="brand_name">{page_info.page_name} Lifetime Basic Membership</div>
                <div className="faq">
                    {page_info.faq.map(f => {
                        return(
                            <div className="box">
                                <div className="question">
                                    {f.question}
                                </div>
                                <div className="answer">
                                    {f.answer}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>            
        )
    }
}    