import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter } from "react-router-dom";
import $ from 'jquery';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');
const website_info_all = require('../' + folder._ + '/Data/website_info.json');
const page_info_all = require('../' + folder._ + '/Masters/Data/page_info_all.json');
let website_info;
let page_info;
let lang = getCookie("lang");


export default class PageHeaderDesktop extends Component {
    constructor(props) {
        super(props);
        if (lang && lang.length > 0) {
            localhostName.dm = localhostName.dn + lang;
            page_info = website_info_all[lang];
            website_info = website_info_all[lang];
        } else {
            page_info = website_info_all["EN"];
            website_info = website_info_all["EN"];
        };
    }
    render() {
        return (
            <div className="items_page_header desktop_extern_header"> <div className="header_navbar">
                <Link to={website_info.brand_url}><div className="logo_name">{website_info.brand}</div></Link>
                <div className="links_navbar">
                    <Link to="/membership"><div className="nav_tab">{page_info.nav_names.membership}</div></Link>
                    <a class="dropdown">
                        <div className="nav_tab">
                            {website_info.more_nav}
                        </div>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <div className="techniques">
                                <div className=" techniques_nav">{website_info.more_nav}</div>
                                {(website_info.technique_pages || []).map(p => {
                                    return (<div class="dropdown-item" onClick={() => { window.location.href = p.url; }} ><img className="nav_icon" src={"/icons/" + p.icon} />{p.name}</div>)
                                })}
                            </div>
                            <div>
                                <Link class="dropdown-item" to="/aboutus"><img className="nav_icon" src="/icons/group.png" />{page_info.nav_names.about_us}</Link>
                                <Link class="dropdown-item" to="/contact"><img className="nav_icon" src="/icons/letter.png" />{page_info.nav_names.contact}</Link>
                                <Link class="dropdown-item" to="/terms-and-conditions"><img className="nav_icon" src="/icons/terms-and-conditions.png" />{page_info.nav_names.tc_cs}</Link>
                                <Link class="dropdown-item" to="/privacy-policy"><img className="nav_icon" src="/icons/privacy-policy.png" />{page_info.nav_names.privacy}</Link>
                            </div>
                        </div>
                    </a>
                    {website_info.languages.length > 1 ?
                        <div className="nav_lang">
                            {website_info.languages.map(l => {
                                return (
                                    <div onClick={() => { setCookie("lang", l.code, 365); window.location.reload(); }}><img src={"/icons/" + l.icon} />{l.name}</div>
                                )
                            })}
                        </div> : ""}
                </div>
                <div class="nav_tab login">
                    {this.props.logged ?
                        <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.props.user.firstname} {this.props.user.lastname.charAt(0)}.</span></Link></div>
                        :
                        <button onClick={() => $('#Login_modal').addClass('show')}>Login</button>
                    }
                </div>
            </div>
            </div>
        )
    }
}  