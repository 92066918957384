import React, { Component } from 'react';
import axios from 'axios';
import ModalBoot from '../../components/Modal';
import $ from 'jquery';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import folder from '../../System/folder.json';
let localhostName = require('../../' + folder._ + '/config.json');
const geoUrl =
    "https://unpkg.com/world-atlas@2.0.2/countries-110m.json";

export default class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.item_name,
            buddies: [],
            user_data: (this.props.user_data || { infos: {} }),
            user: (this.props.user || {}),
            items_over: (this.props.items_over || []),
            new_items: [],
            new_countries: [],
            item_country: "",
            items_select: [],
            new_bucket_items: [],
            homeitem: {},
            homeitem_array: [],
            homeitem_letters: "",
            photo_upload_error: ""
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeHomeitem = this.onChangeHomeitem.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + '/api/users/userdata', { withCredentials: true })
            .then(res => {
                let user_data = res.data.user_data;
                user_data.infos = (user_data.infos || { infos: {} })
                this.setState({ user_data: user_data, homeitem: (user_data.infos.homeitem || {}) });
            })
            .catch(function (error) {
                console.log(error);
            });
        $(document).ready(function () {
            var element = $(".tabs");
            $(".scroll_right").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition + 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".top_bar").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".top_bar").removeClass("hide_left");
                };
            });
            $(".scroll_left").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition - 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if (newPosition <= 0) {
                    $(".top_bar").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".top_bar").removeClass("hide_right");
                };
            });
            $(".tabs").scroll(function () {
                var newPosition = element.scrollLeft();
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".top_bar").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".top_bar").removeClass("hide_left");
                };
                if (newPosition <= 0) {
                    $(".top_bar").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".top_bar").removeClass("hide_right");
                };
            });
        })
    }
    reload() {
        axios.get(localhostName.host + '/api/users/userdata', { withCredentials: true })
            .then(res => {
                let user_data = res.data.user_data;
                user_data.infos = (user_data.infos || { infos: {} })
                this.setState({ user_data: user_data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
        if ([e.target.id] == "item_country") {
            console.log(e.target.value);
            console.log(this.state.items_over);
            let items_select = this.state.items_over.filter((c) => c.country === e.target.value);
            this.setState({ items_select: items_select });
            console.log(items_select);
        };
    }
    onChangeInfo(e) {
        let user_data = this.state.user_data;
        user_data.infos = (user_data.infos || {});
        user_data.infos[e.target.id] = e.target.value;
        console.log(user_data);
        this.setState({ user_data: user_data });
    }
    onChangeHomeitem(e) {
        let user_data = this.state.user_data;
        user_data.infos = (user_data.infos || {});
        user_data.infos[e.target.id] = e.target.value;
        this.setState({ homeitem: { name: e.target.value } });
        let item_letters = { item_letters: [e.target.value.masterstring(0, 2)] };
        if (e.target.value.length > 1 && e.target.value.toLowerCase().masterstring(0, 2) !== this.state.homeitem_letters.masterstring(0, 2)) {
            axios.get(localhostName.host + '/Data/items_geo/' + e.target.value.masterstring(0, 2))
                .then(res => {
                    this.setState({ homeitem_array: res.data, homeitem_letters: e.target.value.toLowerCase().masterstring(0, 2) });
                    $(".list_compare_items").css("display", "inherit");
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            this.setState({ homeitem_letters: e.target.value.toLowerCase() });
        };
    }
    saveHomeitem(item) {
        let homeitem = { homeitem: item };
        axios.post(localhostName.host + '/api/users/homeitem', homeitem, { withCredentials: true })
            .then(res => {
                this.reload();
                this.showFormButton("showAddInfo", false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    addCountry() {
        let new_countries = { countries: this.state.new_countries };
        console.log(new_countries);
        if (this.state.new_countries.length > 0) {
            axios.post(localhostName.host + '/api/users/addcountry', new_countries, { withCredentials: true })
                .then(res => {
                    console.log(res.data);
                    this.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        };
    }
    addCity() {
        let new_items = { items: this.state.new_items };
        axios.post(localhostName.host + '/api/users/additem', new_items, { withCredentials: true })
            .then(res => {
                this.reload();
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    addBucketCity() {
        let new_items = { items: this.state.new_bucket_items };
        console.log(this.state.new_bucket_items);
        axios.post(localhostName.host + '/api/users/addbucketitem', new_items, { withCredentials: true })
            .then(res => {
                this.reload();
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    saveInfo() {
        let infos = { infos: this.state.user_data["infos"] };
        axios.post(localhostName.host + '/api/users/saveinfos', infos, { withCredentials: true })
            .then(res => {
                this.reload();
                this.showFormButton("showAddInfo", false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    change_add_country(e) {
        let new_countries = this.state.new_countries;
        if (e.target.checked) {
            new_countries.push(e.target.value);
        } else if (!e.target.checked) {
            let index = new_countries.findIndex((c) => c === e.target.value);
            new_countries.splice(index, 1);
        };
        this.setState({ new_countries: new_countries });
    }
    change_add_item(e) {
        let new_items = this.state.new_items;
        if (e.target.checked) {
            new_items.push(JSON.parse(e.target.value));
        } else if (!e.target.checked) {
            let index = new_items.findIndex((c) => c === JSON.parse(e.target.value));
            new_items.splice(index, 1);
        };
        this.setState({ new_items: new_items });
    }
    change_add_bucket_item(e) {
        let new_items = this.state.new_bucket_items;
        if (e.target.checked) {
            new_items.push(JSON.parse(e.target.value));
        } else if (!e.target.checked) {
            let index = new_items.findIndex((c) => c === JSON.parse(e.target.value));
            new_items.splice(index, 1);
        };
        console.log(new_items);
        this.setState({ new_bucket_items: new_items });
    }
    handleFileChange = (e) => {
        this.setState({ photo_upload_error: "" });
        const reader = new FileReader();
        if (e.target.files[0].size > 350000) {
            this.setState({ photo_upload_error: "Image too big. Maximal size is 350kb." });
            return;
        }
        reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
                const imageWidth = image.width;
                const imageHeight = image.height;
                console.log('Image Dimensions:', imageWidth, 'x', imageHeight, 'pixels');
                if (imageWidth < 200 || imageHeight < 200) {
                    console.log("tooB");
                    this.setState({ photo_upload_error: "Image too small. Minimal size is 200px x 200px." });
                    return;
                };
                this.setState({ setPreviewImage: reader.result });
            };
        };
        reader.readAsDataURL(e.target.files[0]);
        this.setState({ selectedFile: e.target.files[0] });
    }
    handleUpload() {
        const { selectedFile } = this.state;
        console.log(selectedFile);
        const formData = new FormData();
        formData.append('photo', selectedFile, selectedFile.name);
        axios.post(localhostName.host + '/api/users/uploadprofilephoto', formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            if(res.data.message === "Photo upload successful."){
                window.location.replace("/profile");
            };
        })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        let user_data = this.state.user_data;
        let user = this.state.user;
        function calculateAge(birthday) {
            const ageDifMs = Date.now() - new Date(birthday).getTime();
            const ageDate = new Date(ageDifMs);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }
        let age = calculateAge((user_data.infos || "").birthday);
        let items_list = (this.state.user_data.items_played || []);
        let items_played = (this.state.user_data.played_items || []);
        let items_bucket = (this.state.user_data.bucket_items || []);
        let countries_played = (user_data.played_countries || []);
        countries_played = countries_played.filter(c => c !== null);
        let courses_played = (user_data.played_courses || []);
        let courses_bucket = (this.state.user_data.bucket_courses || []);
        let countries = {
            UK: "United Kingdom", DE: "Germany", ES: "Spain", PT: "Portugal", FR: "France", IE: "Ireland", IT: "Italy", TR: "Turkey", NL: "Netherlands",
            US: "USA", MX: "Mexico", CA: "Canada", AU: "Australia", NZ: "New Zealand", JP: "Japan", CR: "Costa Rica", DO: "Dominican Republic", TH: "Thailand", CO: "Colombia", KR: "South Korea", VN: "Vietnam", BR: "Brazil", AR: "Argentina", CH: "Switzerland", AT: "Austria", ZA: "South Africa", JM: "Jamaica", IS: "Iceland", SE: "Sweden", TW: "Taiwan", NO: "Norway", IN: "New Delhi", BS: "Bahamas", KE: "Kenya", MU: "Mauritius", PHL: "Philippines", PE: "Peru", MY: "Malaysia", AE: "UAE", CN: "China", BE: "Belgium", HU: "Hungary", GR: "Greece", GI: "Gibraltar", CZ: "Czech Republic", DK: "Denmark", HK: "Hong Kong", SG: "Singapore", ID: "Indonesia", NP: "Nepal", QA: "Qatar", MT: "Malta", MC: "Monaco", KY: "Cayman Islands", CL: "Chile", NA: "Namibia", GH: "Ghana"
        };
        let countries_names = {};
        Object.keys(countries).map((key, index) => {
            countries_names[countries[key]] = key;
        });
        countries_names = Object.keys(countries_names).sort().reduce(
            (obj, key) => {
                obj[key] = countries_names[key];
                return obj;
            },
            {}
        );
        let countries_played_geo = [...countries_played];
        if (countries_played_geo.includes("UK")) {
            countries_played_geo.push("GB");
        };
        let home_items = [];
        if (this.state.homeitem_letters.length > 1) {
            let homeitem_array = this.state.homeitem_array;
            home_items = homeitem_array.filter(c => c.name.toLowerCase().includes(this.state.homeitem_letters));
            home_items.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        };
        return (
            <div className="user_profile">
                <div className="profile_header">
                    <ComposableMap>
                        <Geographies geography={geoUrl}>
                            {({ geographies }) =>
                                geographies.map((geo) =>
                                (
                                    <Geography style={(countries_played_geo.includes(geo.properties["Alpha-2"]) ? { default: { fill: "#009765" } } : { default: { fill: "rgb(172 172 172)" } })} key={geo.rsmKey} geography={geo} />
                                ))
                            }
                        </Geographies>
                    </ComposableMap>
                    <div className="container profile_name_info">
                        <div className="profile_photo" onClick={() => { this.showFormButton("showPhotoUpload", true); }}>
                            <i className="fas fa-edit" onClick={() => { this.showFormButton("showPhotoUpload", true); }}></i>
                            <img src={(user_data.profile_photo || "").length > 0 ? ("https://golftus.s3.eu-west-2.amazonaws.com/" + user_data.profile_photo) : "/profile_avatar.png"} />
                        </div>
                        <div className="profile_name">
                            {/*<i className="fas fa-edit" onClick={() => { this.showFormButton("showAddInfo", true); }}></i>*/}
                            <div>{user.firstname} {user.lastname}</div>
                            {/*<div>{age ? (age + " years") : <span className="not_entered" onClick={() => { this.showFormButton("showAddInfo", true); }}><i className="fas fa-edit"></i>Age not entered yet</span>}</div>
                            <div>{user_data.infos.homeitem ? (user_data.infos.homeitem.name + ", " + user_data.infos.homeitem.country) : <span className="not_entered" onClick={() => { this.showFormButton("showAddInfo", true); }}><i className="fas fa-edit"></i>City not entered yet</span>}</div>
                        <div>{user_data.infos.profession ? <span>{user_data.infos.profession}</span> : <span className="not_entered" onClick={() => { this.showFormButton("showAddInfo", true); }}><i className="fas fa-edit"></i>Profession not entered yet</span>}</div>*/}
                        </div>
                    </div>
                </div>
                {/*<div className="message_action">
                    <div className="other_profile_message" onClick={() => { this.showFormButton("showClubMailSend", true); }}>
                        <img src="/icons/email.png" />
                        <div>ClubMail</div>
                    </div>
                    <div className="other_profile_message" onClick={() => { this.showFormButton("showBuddyRequest", true); }}>
                        <img src="/icons/request.png" />
                        <div>+Buddy</div>
                    </div>
                    <div className="other_profile_message" onClick={() => { this.showFormButton("showClubMailSend", true); }}>
                        <img src="/icons/pin.png" />
                        <div>Save</div>
                    </div>
                </div>*/}
                <div className="top_bar hide_left">
                    {/*<div className="info_tab scroll_b scroll_left"><img class="icon" src="/icons/scroll.png" /></div>*/}
                    <div className="tabs">
                        <div className="selected">Profile</div>
                        {/*<div>Collections</div>*/}
                        {/*<div>Photos</div>*/}
                        {/*<div>Course Ratings</div>
                        <div>Profession</div>*/}
                    </div>
                    {/*<div className="info_tab scroll_b scroll_right"><img class="icon" src="/icons/scroll.png" /></div>*/}
                </div>
                <div className="container">
                    <div className="profile_profile">
                        <div className="played_and_bucket">
                            <i className="fas fa-edit box_icon" onClick={() => { this.showFormButton("showAddCities", true); }}></i>
                            <div className="main_title">Techniques</div>
                            <div className="master_title">Learned ({countries_played.length})</div>
                            <div className="box">
                                <div className="countries_list">
                                    {countries_played ?
                                        countries_played.map((c) => {
                                            return (
                                                <div className="country">{countries[c]}</div>
                                            )
                                        })
                                        :
                                        ""
                                    }
                                    <div className="add_new_country" onClick={() => { this.showFormButton("showAddCountries", true); }}><i className="fa fa-globe" aria-hidden="true"></i>Add technique</div>
                                </div>
                            </div>
                            {/*<div className="box">
                                <div className="title">Courses ({courses_played.length})</div>
                                <div className="items_list">
                                    {console.log(courses_played)}
                                    {courses_played ?
                                        courses_played.map((c) => {
                                            return (
                                                <div className="item">{c.name}</div>
                                            )
                                        })
                                        :
                                        ""
                                    }
                                    <div className="add_new_country" onClick={() => { this.showFormButton("showAddCities", true); }}><i className="fa-solid fa-golf-ball-tee"></i>Add course</div>
                                </div>
                            </div>*/}
                            <div className="bucket_list">
                                <div className="master_title">Top Favourite ({items_played.length})</div>
                                <div className="box">
                                    <div className="items_list">
                                        {items_bucket ?
                                            items_bucket.map((c) => {
                                                return (
                                                    <div className="item">{c.name}</div>
                                                )
                                            })
                                            :
                                            ""
                                        }
                                        <div className="add_new_country" onClick={() => { this.showFormButton("showAddBucket", true); }}><i className="fa-solid fa-item"></i>Add technique</div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="golf_infos_short">
                                <div className="box">
                                    <div>Playing Golf (years)</div>
                                    <div>{user_data.infos.startedgolf ? calculateAge(user_data.infos.startedgolf) : <span className="not_entered" onClick={() => { this.showFormButton("showAddInfo", true); }}><i className="fas fa-edit"></i>Starting Date not entered yet</span>}</div>
                                </div>
                                <div className="box">
                                    <div>Online</div>
                                    <div>Now online</div>
                                </div>
                            </div>*/}
                        </div>
                        {/*<div className="info_data profile_info">
                            <i className="fas fa-edit box_icon" onClick={() => { this.showFormButton("showAddInfo", true); }}></i>
                            <div className="title">About Me</div>
                            <div className="golf_infos_short">
                                <div className="box">
                                    <div>Age</div>
                                    <div>{age ? (age + " years") : <span className="not_entered" onClick={() => { this.showFormButton("showAddInfo", true); }}><i className="fas fa-edit"></i>Birthday not entered yet</span>}</div>
                                </div>
                                <div className="box">
                                    <div>Languages</div>
                                    <div>{user_data.infos.languages ? user_data.infos.languages : <span className="not_entered" onClick={() => { this.showFormButton("showAddInfo", true); }}><i className="fas fa-edit"></i>Languages not entered yet</span>}</div>
                                </div>
                                <div className="box">
                                    <div>Profession</div>
                                    <div>{user_data.infos.profession ? user_data.infos.profession : <span className="not_entered" onClick={() => { this.showFormButton("showAddInfo", true); }}><i className="fas fa-edit"></i>Profession not entered yet</span>}</div>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
                <ModalBoot show={this.state.showClubMailSend} hide={this.showClubMailSend} content={"showClubMailSend"} className="modal_userprofile club_mail">
                    <div className="send_clubmail">
                        <div className="clubmail_header">
                            <div className="text">Write a ClubMail to {(user_data.name || "").split(" ")[0]}</div>
                            <div className="close" onClick={() => { this.showFormButton("showClubMailSend", false); }}>X</div>
                        </div>
                        <div className="textbox">
                            <textarea placeholder="Write message ..."></textarea>
                        </div>
                        <div className="clubmail_footer">
                            <div className="cancel">Cancel</div>
                            <div className="send">Send</div>
                        </div>
                    </div>
                </ModalBoot >
                <ModalBoot show={this.state.showPhotoUpload} hide={this.showPhotoUpload} content={"showPhotoUpload"} className="modal_userprofile upload_photo_modal club_mail">
                    <div className="send_clubmail">
                        <div className="clubmail_header">
                            <div className="text">Profile Photo Upload</div>
                            <div className="close" onClick={() => { this.showFormButton("showPhotoUpload", false); }}>X</div>
                        </div>
                        {this.state.setPreviewImage ? <img className="previewImage" src={this.state.setPreviewImage} /> : ""}
                        {this.state.photo_upload_error.length > 0 ? <p className="login_error">!Login failed. Wrong email or password.</p> : ""}
                        <div className="textbox">
                            <input type="file" onChange={(e) => this.handleFileChange(e)} />
                        </div>
                        <div className="clubmail_footer">
                            <div className="cancel">Cancel</div>
                            <div className="send" onClick={() => this.handleUpload()}>Upload</div>
                        </div>
                    </div>
                </ModalBoot >
                <ModalBoot show={this.state.showBuddyRequest} hide={this.showBuddyRequest} content={"showBuddyRequest"} className="modal_userprofile club_mail buddy_request">
                    <div className="send_clubmail">
                        <div className="clubmail_header">
                            <div className="text">Buddy Request to {(user_data.name || "").split(" ")[0]}</div>
                            <div className="close" onClick={() => { this.showFormButton("showBuddyRequest", false); }}>X</div>
                        </div>
                        <div className="textbox">
                            <div>Connect only with people you know. This way, you can build a trustworthy golf network.</div>
                        </div>
                        <div className="clubmail_footer">
                            <div className="cancel">Cancel</div>
                            <div className="send">Send request</div>
                        </div>
                    </div>
                </ModalBoot >
                <ModalBoot show={this.state.showAddCountries} hide={this.showFormButton} content={"showAddCountries"} className="modal_userprofile add_country_modal">
                    <div className="headline">Played Countries</div>
                    <div className="played">
                        <div className="played_c">
                            <div className="played_t">
                                Countries you played
                            </div>
                            <div className="played_list">
                                {countries_played ?
                                    countries_played.map((c) => {
                                        return (
                                            <div className="country">{countries[c]}</div>
                                        )
                                    })
                                    :
                                    <div className="none_played" >No country added yet</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="add_items">
                        <div className="select_country">
                            <p className="title_add">Add a new Country</p>
                            {Object.keys(countries_names).map((key) => {
                                if (!countries_played.includes(countries_names[key])) {
                                    return (
                                        <div><input type="checkbox" value={countries_names[key]} onChange={(e) => { this.change_add_country(e) }} />{key}</div>
                                    )
                                };
                            })}
                            <div className="save_button" onClick={() => this.addCountry()}>Add & Save</div>
                        </div>
                    </div>
                </ModalBoot >
                <ModalBoot show={this.state.showAddCities} hide={this.showFormButton} content={"showAddCities"} className="modal_userprofile add_country_modal add_items_modal">
                    <div className="headline">Played Cities</div>
                    <div className="played">
                        <div className="played_c">
                            <div className="played_t">
                                Cities you played
                            </div>
                            <div className="played_list">
                                {items_played.length > 0 ?
                                    items_played.map((c) => {
                                        return (
                                            <div className="country">{c.name}</div>
                                        )
                                    })
                                    :
                                    <div className="none_played" >No item added yet</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="add_items">
                        <div className="select_country">
                            <p className="title_add">Add a new City</p>
                            <select id="item_country" value={this.state.item_country} onChange={(e) => this.onChange(e)}>
                                <optgroup label="Country">
                                    <option value="">Select Country of the City</option>
                                    {Object.keys(countries_names).map((key, index) => {
                                        return (
                                            <option value={countries_names[key]}>{key}</option>
                                        )
                                    })}
                                </optgroup>
                            </select>
                            <div className="select_item" key={this.state.item_country}>
                                <div className="select_item_head">{this.state.items_select.length > 0 ? "Select City" : "Please first select the country."}</div>
                                {this.state.items_select.map((c) => {
                                    if ((items_played.findIndex((c2) => c2.name === c.name)) < 0) {
                                        return (
                                            <div className="form-check">
                                                <input key={this.state.item_country} className="form-check-input" type="checkbox" value={JSON.stringify(c)} id="flexCheckCheckedDisabled" onChange={(e) => { this.change_add_item(e) }} />
                                                <label className="form-check-label" for="flexCheckCheckedDisabled">
                                                    {c.name}
                                                </label>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            {this.state.new_items.length > 0 ? <div className="save_button" onClick={() => this.addCity()}>Add & Save</div> : ""}
                        </div>
                    </div>
                </ModalBoot >
                <ModalBoot show={this.state.showAddBucket} hide={this.showFormButton} content={"showAddBucket"} className="modal_userprofile add_country_modal bucket_modal">
                    <div className="headline">Bucket List</div>
                    <div className="played">
                        <div className="">
                            <div className="played_t">
                                Cities in your bucket list
                            </div>
                            <div className="played_list">
                                {items_bucket.length > 0 ?
                                    items_bucket.map((c) => {
                                        return (
                                            <div className="country">{c.name}</div>
                                        )
                                    })
                                    :
                                    <div className="none_played" >No item added yet</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="add_items">
                        <div className="select_country bucket_items">
                            <p className="title_add">Add a new City</p>
                            <select id="item_country" value={this.state.item_country} onChange={(e) => this.onChange(e)}>
                                <optgroup label="Country">
                                    <option value="">Select Country of the City</option>
                                    {Object.keys(countries_names).map((key, index) => {
                                        return (
                                            <option value={countries_names[key]}>{key}</option>
                                        )
                                    })}
                                </optgroup>
                            </select>
                            <div className="select_item" >
                                <div className="select_item_head">{this.state.items_select.length > 0 ? "Select City" : "Please first select the country."}</div>
                                {this.state.items_select.map((c) => {
                                    if ((items_bucket.findIndex((c2) => c2.name === c.name)) < 0) {
                                        return (
                                            <div className="form-check">
                                                <input key={this.state.item_country} className="form-check-input" type="checkbox" value={JSON.stringify(c)} id="flexCheckCheckedDisabled" onChange={(e) => { this.change_add_bucket_item(e) }} />
                                                <label className="form-check-label" for="flexCheckCheckedDisabled">
                                                    {c.name}
                                                </label>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="save_button" onClick={() => this.addBucketCity()}>Add & Save</div>
                        </div>
                    </div>
                </ModalBoot >
                <ModalBoot show={this.state.showAddInfo} hide={this.showFormButton} content={"showAddInfo"} className="modal_userprofile add_country_modal bucket_modal">
                    <div className="headline">Profile Info</div>
                    <div className="info_form">
                        <Form.Group className="input_form" >
                            <Form.Label>Home City</Form.Label>
                            <Form.Control type="text" _ref="firstna" placeholder="City" id="homeitem" onChange={(e) => this.onChangeHomeitem(e)} value={this.state.homeitem.name} />
                            <div className="list_compare_items">
                                {home_items.map((c) => {
                                    return (
                                        <div className="compare_item">
                                            <div className="name">{c.name}, {c.country === "US" ? (c.state + ",") : ""} {c.country}</div>
                                            <div className="select" onClick={() => this.saveHomeitem(c)}>select</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Form.Group>
                        <Form.Group className="input_form" >
                            <Form.Label>Birthday</Form.Label>
                            <input class="form-control" id="birthday" name="date" placeholder="MM/DD/YYY" type="date" onChange={(e) => this.onChangeInfo(e)} value={this.state.user_data.infos.birthday} />
                        </Form.Group>
                        <Form.Group className="input_form" >
                            <Form.Label>Started Playing Golf</Form.Label>
                            <input class="form-control" id="startedgolf" name="date" placeholder="MM/DD/YYY" type="date" onChange={(e) => this.onChangeInfo(e)} value={this.state.user_data.infos.startedgolf} />
                        </Form.Group>
                        <Form.Group className="input_form" >
                            <Form.Label>Profession</Form.Label>
                            <Form.Control type="text" _ref="lastname" placeholder="Profession" id="profession" onChange={(e) => this.onChangeInfo(e)} value={this.state.user_data.infos.profession} />
                        </Form.Group>
                        {/*<Form.Group className="input_form" >
                            <Form.Label>Citizen</Form.Label>
                            <select id="country_citizen" value={this.state.user_data.infos.country_citizen} onChange={(e) => this.onChangeInfo(e)}>
                                <optgroup label="Country">
                                    <option value="">Country</option>
                                    {Object.keys(countries_names).map((key, index) => {
                                        return (
                                            <option value={countries_names[key]}>{key}</option>
                                        )
                                    })}
                                </optgroup>
                            </select>
                        </Form.Group>*/}
                        {/*<Form.Group className="input_form" >
                            <Form.Label>Languages</Form.Label>
                            <Form.Control type="text" _ref="lastname" placeholder="Languages" id="languages" onChange={(e) => this.onChange(e)} value={this.state.user_data.infos.languages} />
                        </Form.Group>*/}
                    </div>
                    <div className="save_button" onClick={() => this.saveInfo()}>Save</div>
                </ModalBoot >
            </div>
        )
    }
}    