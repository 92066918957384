import React, {Component} from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import $ from 'jquery';
import ItemOverview from './ItemOverview';
import LoginModal from './LoginModal';

export default class SingleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            clicked: false,
        };
    }
    componentDidMount() {
        let self = this;
        $("#" + self.props.id + " .btn-close").click(function(){
            $("#" + self.props.id).removeClass("show");
        });
        $("#" + self.props.id).click(function(e) {
            var container = $("#" + self.props.id + " #" + self.props.child_id);
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $("#" + self.props.id).removeClass("show"); 
            }
          });    
    }
    login(e){
        e.preventDefault();
        this.setState({clicked: true});
    }
    render() {
        return (
            <div id={this.props.id} className="SingleModal">
                <div className="content" id={this.props.child_id}>
                    <div class="bucket_modal btn-close"></div>
                    {this.props.children}
                </div>
            </div> 
        )
    }
}    