import React, { Component } from 'react';
import axios from 'axios';
import { setCookie, getCookie, delete_cookie } from '../../functions/functions.js';
import $ from 'jquery';
import folder from '../folder.json';
let master_name;
let lang;

export default class StockBanner extends Component {
    constructor(props) {
        super(props);
        lang = getCookie("lang");
        master_name = window.location.pathname.split("/")[2];
        let url = new URL(window.location.href);
        let id = url.searchParams.get("id");
        let user_data = this.props.user_data;
        this.state = {
            userInfo: this.props.userInfo,
            user_data: (user_data || { infos: {} }),
            logged: false,
            master_name: window.location.pathname.split("/")[2],
            item: this.props.selected_item,
        };
    }
    render() {
        let item = (this.state.item || {});
        let info = item.companyData;
        let items_learned = (this.props.logged ? (this.state.user_data.learned_items || []) : []);
        let items_favourite = (this.props.logged ? (this.state.user_data.favourite_items || []) : []);
        let learned_index = items_learned.findIndex(c => c === item.id);
        let favourite_index = items_favourite.findIndex(c => c === item.id);
        let learned = (learned_index > -1 ? true : false);
        let favourite = (favourite_index > -1 ? true : false);
        return (
            <div className="company-card">
                <div className="company-header">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                        alt="Microsoft Logo"
                        className="company-logo"
                    />
                    <div className="company-info">
                        <h1>{item.name}</h1>
                        <span className="company-ticker">{item.ticker}</span>
                    </div>
                </div>
                <div className="company-numbers">
                    <div className="company-stats">
                        <div className="stat-top">
                            <div className="stat-item">
                                <h2>{info.sharePrice}</h2>
                                <span>Share price</span>
                            </div>
                            <div className="stat-item">
                                <h2>{info.marketCap}</h2>
                                <span>Marketcap</span>
                            </div>
                            <div className="stat-item">
                                <h2>-{info.changeOneDay}</h2>
                                <span>Change (1 day)</span>
                            </div>
                            <div className="stat-item">
                                <h2>{info.changeOneYear}</h2>
                                <span>Change (1 year)</span>
                            </div>
                            <div className="stat-item">
                                <h2>{info.country}</h2>
                                <span>Country</span>
                            </div>
                        </div>
                        <div className="stat-bottom">
                            <div className="company-tags">
                                <span className="tag">👨‍💻 Software</span>
                                <span className="tag">👨‍💻 Tech</span>
                                <span className="tag">🎮 Video games</span>
                                <span className="tag">🇺🇸 Dow Jones</span>
                                <span className="tag">🤖 AI</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="overview_close" src="/icons/close_white.png" onClick={() => { this.props.refresh(); $(".modal_ItemOverview .btn-close").trigger("click"); }} />
            </div>
        )
    }
}    