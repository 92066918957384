import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import ModalBoot from '../components/Modal.js';
import { isMobile } from "react-device-detect";
import PageHeaderDesktop from './PageHeaderDesktop.js';
import PageHeaderMobile from './PageHeaderMobile.js';
import { getCookie } from '../functions/functions.js';
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');
const website_info_all = require('../' + folder._ + '/Data/website_info.json');
const contentAll = require('../' + folder._ + '/Data/content.json');
const content_comingAll = require('../' + folder._ + '/Data/content_coming.json');
let content;
let content_coming;
let lang;
let website_info;

export default class MobileStart extends Component {
  constructor() {
    super();
    this.state = {
    };
    lang = getCookie("lang");
    if (lang && lang.length > 0) {
      content = contentAll[lang];
      content_coming = content_comingAll[lang];
      website_info = website_info_all[lang];
    } else {
      content = contentAll["EN"];
      content_coming = content_comingAll["EN"];
      website_info = website_info_all["EN"];
    };
    this.showFormButton = this.showFormButton.bind(this);
  }
  componentDidMount() {
    let url = new URL(window.location.href);
    let pay_suc = url.searchParams.get("success");
    if (pay_suc === "basic") {
      this.showFormButton("showPaySuc", true);
    };
    axios.post(localhostName.host + '/api/pagetrackers/add', { type: "page", "page": window.location.pathname })
      .then(res => {
      })
      .catch((error) => {
      });
  }
  showFormButton(targetShow, change, id, tab) {
    this.setState({ [targetShow]: change });
  }
  render() {
    console.log(website_info.start);
    return (
      <div className="mobile mobile_start">
        {isMobile ?
          <PageHeaderMobile key={this.props.logged} logged={this.props.logged} user={this.props.user} />
          :
          <PageHeaderDesktop key={this.props.logged} logged={this.props.logged} user={this.props.user} />
        }
        <div className="pageContent">
          <div className="flexContent">
            <div className="start_select_c">{website_info.start.sc}</div>
            <div className="start_select_t">
              <h4>{website_info.start.title}</h4>
              <div>{website_info.start.txt}</div>
            </div>
            <div className="content_list">
              {content.map(c => {
                return (
                  <Link to={c.url + "?s=top"} className="main_c">
                    <img src={c.image} />
                    <div className="overlay"></div>
                    <div className="c_text">
                      <div>{c.name}</div>
                      <div className="desc">{c.desc}</div>
                    </div>
                  </Link>
                )
              })}
            </div>
            <div className="coming_soon">
              <h4>Coming soon ...</h4>
              <div>{website_info.start.coming}</div>
              <div className="content_list">
              {content_coming.map(c => {
                return (
                  <Link to={c.url + "?s=top"} className="main_c">
                    <img src={c.image} />
                    <div className="overlay"></div>
                    <div className="c_text">
                      <div>{c.name}</div>
                      <div className="desc">{c.desc}</div>
                    </div>
                  </Link>
                )
              })}
            </div>
            </div>
          </div>
        </div>
        <ModalBoot show={this.state.showPaySuc} hide={this.showFormButton} content={"showPaySuc"} className="modal_Login modal_success">
          <h1>Congratulations!</h1>
          <div>Your payment has been confirmed, and you are now a Basic {website_info.brand} Member for life. This entitles you to full and unrestricted access to all item data, 24 hours a day, 7 days a week.</div>
          <button class="btn btn-success btn-block float-right" onClick={() => {
            this.showFormButton("showPaySuc", false);
            window.history.pushState({}, document.title, window.location.pathname);
          }}> Thanks</button>
        </ModalBoot >
      </div>
    )
  }
}

