import React, {Component} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import localhostName from "./config.json";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import $ from 'jquery';

export default class RegisterNon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailerror: false,
            registerStage: "name",
			username: '',
			password: '',
            step: "Closed",
            error_text: "",
            email: "",
            firstname: "",
            lastname: ""
        }
        this.onSubmitNext = this.onSubmitNext.bind(this);
        this.onSubmitNextEmail = this.onSubmitNextEmail.bind(this);
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }
    onSubmit = e => {
        e.preventDefault(this.state.step );    
        const newUser = {
            email: this.state.email,
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            username: this.state.username,
        };
        axios.post(localhostName.host + '/api/users/register', newUser)
            .then(res => window.location.href = '/login')
            .catch(error => { console.log('Logout error') });
    };
    componentDidMount() {
        $("body").removeClass("startBackColor");   
    }
    onSubmitNext(e, nextStage){
        e.preventDefault();
        this.setState({ step: nextStage });
    }
    submitData(e){
        e.preventDefault();
        let new_user = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            password: this.state.password,
        };
        let check = true;
        let state = this.state;
        let error_text = "";
        if (state.password !== state.password_repeat){
            check = false;
            error_text = "Password repeat is wrong."
        };
        if (!(state.password.length >0)){
            check = false;
            error_text = "Password is missing."
        };
        if (state.email !== state.email_repeat){
            check = false;
            error_text = "E-Mail repeat is wrong."
        };
        if (!(state.email.length >0)){
            check = false;
            error_text = "E-Mail is missing."
        };
        if (!(state.lastname.length >0)){
            check = false;
            error_text = "Lastname is missing."
        };
        if (!(state.firstname.length >0)){
            check = false;
            error_text = "Firstname is missing."
        };
        console.log(error_text);
        if(check){
            axios.post(localhostName.host + '/api/users/signup',new_user)
            .then(res => {
                if(res.data != false){
                    if(res.data.user_exist){
                        error_text = "This E-Mail is already in use.";
                        this.setState({ error_text });
                    }else{
                        this.setState({ step: "Payment" });
                    };
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        };
        this.setState({ error_text });
    }
    onSubmitNextEmail(e, nextStage){
        e.preventDefault();
        let checkemail = this.state.userlists.find(x => x.email === this.state.email);
        axios.get(localhostName.host + '/api/users//checkusers/' + this.state.email)
            .then(res => {
                console.log(res);
                if(res.data.length > 0){
                    console.log("email schon da");
                    this.setState({ emailmessage: "Diese Email wird schon verwendet.", emailerror: true });
                }else{
                    if(checkemail == undefined){
                        console.log("error");
                        this.setState({ emailmessage: "Diese Email ist leider nicht freigegeben.", emailerror: true });
                    }else{
                        this.setState({ registerStage: nextStage });
                    };
                };
            })
            .catch(function (error) {
                console.log(error);
            }); 
    }
    stageBack(beforeStage){
        this.setState({ registerStage: beforeStage });
        console.log(this.state); 
    }
    render() {
        return (
            <Container className="register">
                <div>
                    <Row className="register_box">
                        <div className="brand_name">Golftùs Basic Membership</div> 
                        <div className={this.state.step == "Payment" ? "payment_form" : "signUp"}>
                            <div className="register_head">
                                <div>Create Account</div>
                            </div>
                            <div className="steps_progress">
                                <div className={"step_name " + (this.state.step === "Data" ? "selected" : "")}>
                                    <i></i>
                                    <span>Registration Data</span>
                                </div>
                                <i className={"step_line " + (this.state.step === "Payment" ? "selected" : "")}></i>
                                <div className={"step_name " + (this.state.step === "Payment" ? "selected" : "")}>
                                    <i></i>
                                    <span>Payment</span>
                                </div>
                            </div>
                            {this.state.step !== "Closed" ? "" :
                            <div>
                                <h1>Unfortunately there are too many registration the last 24 hours. Sign up your E-Mail to be informed when you can register.</h1>
                                <Form.Group className="input_form" >
                                    <Form.Label>E-Mail*</Form.Label>
                                    <Form.Control type="email" _ref="email" placeholder="E-Mail" id="email" onChange={(e) => this.onChange(e)} value={this.state.email} required/>
                                </Form.Group>
                                <Button type="submit" className="signSubmit">Get informed when a slot is free</Button>
                            </div>
                            }
                            {this.state.step == "Data" ? <Form onSubmit={(e) => this.onSubmitNext(e, "email")}><Row>
                                <Form.Group className="input_form" >
                                    <Form.Label>Firstname*</Form.Label>
                                    <Form.Control type="text" _ref="firstna" placeholder="Firstname" id="firstname" onChange={(e) => this.onChange(e)}  value={this.state.firstname} required />
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>Lastname*</Form.Label>
                                    <Form.Control type="text" _ref="lastname" placeholder="Lastname" id="lastname" onChange={(e) => this.onChange(e)}  value={this.state.lastname} required />
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>E-Mail*</Form.Label>
                                    <Form.Control type="email" _ref="email" placeholder="E-Mail" id="email" onChange={(e) => this.onChange(e)} value={this.state.email} required/>
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>E-Mail Repeat*</Form.Label>
                                    <Form.Control type="email" _ref="email_repeat" placeholder="E-Mail Repeat" id="email_repeat" onChange={(e) => this.onChange(e)} value={this.state.email_repeat} required/>
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>Password*</Form.Label>
                                    <Form.Control type="password" _ref="password" placeholder="Password" id="password" onChange={(e) => this.onChange(e)} value={this.state.password} required/>
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>Password Repeat*</Form.Label>
                                    <Form.Control type="password" _ref="password_repeat" placeholder="Password Repeat" id="password_repeat" onChange={(e) => this.onChange(e)} value={this.state.password_repeat} required/>
                                </Form.Group>
                                <Form.Group className="input_form membership_lifetime">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                    <label className="form-check-label" for="flexCheckChecked">
                                        Lifetime Basic Membership $99 USD
                                    </label>
                                </Form.Group>
                                {this.state.error_text.length > 0 ? <p className="error_text">!{this.state.error_text}</p> : ""}
                                {this.state.emailerror ? <p className="formerror">! {this.state.emailmessage}</p> : ""}
                                <Button type="submit" className="signSubmit" onClick={(e)=> this.submitData(e)} >Next</Button>
                                <div className="already_login">Already a member? <Link to={"/login"}><span>Log in</span></Link></div>
                            </Row></Form> : ""}
                            {this.state.step == "Payment" ? 
                            <div className="payment" key={this.state.step}>
                                <div className="payment_left">
                                    <div className="user_information">
                                        <div className="title" >Member Information</div>
                                        <div className="info" >
                                            <div>E-Mail</div>
                                            <div>{this.state.email}</div>
                                        </div>
                                        <div className="info" >
                                            <div>Firstname</div>
                                            <div>{this.state.firstname}</div>
                                        </div>
                                        <div className="info" >
                                            <div>Lastname</div>
                                            <div>{this.state.lastname}</div>
                                        </div>
                                    </div>
                                    <div className="title" >Payment</div>
                                    <div>All transactions are secure and encrypted.</div>
                                    <div className="payment_paypal">
                                        <PayPalScriptProvider options={{ "client-id": "sb" }}>
                                            <PayPalButtons
                                                disabled={false}
                                                fundingSource={undefined}
                                                createOrder={(data, actions) => {
                                                    return actions.order.create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: 39,
                                                                    currency_code: 'USD',
                                                                    breakdown: {
                                                                        item_total: { value: 39, currency_code: 'USD' }
                                                                    }
                                                                },
                                                                invoice_id: "www",
                                                                items: []
                                                            },
                                                        ],
                                                    });
                                                }}
                                                onApprove={(data, actions) => {
                                                    console.log(data);
                                                    return actions.order.capture().then((details) => {
                                                        const name = details.payer.name.given_name;
                                                        let order = {
                                                        };
                                                        function delete_cookie( name, path, domain ){
                                                            if( this.getCookie( name ) ) {
                                                            document.cookie = name + "=" +
                                                                ((path) ? ";path="+path:"")+
                                                                ((domain)?";domain="+domain:"") +
                                                                ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
                                                            }
                                                        };
                                                        const responsePromise = fetch(localhostName.host + '/api/orders/add', {
                                                            method: 'post',
                                                            headers: {
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify(order)
                                                        });
                                                        const responsePromise2 = fetch(localhostName.host + '/api/sessions/finished/' + order.id, {
                                                            method: 'post',
                                                            headers: {
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify(order)
                                                        });
                                                        const responsePromise3 = fetch(localhostName.host + '/api/tools/send/order', {
                                                            method: 'post',
                                                            headers: {
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify(order)
                                                        });
                                                        responsePromise.then(function (responseFromServer) {
                                                            if(responseFromServer.status === 200) {
                                                                delete_cookie("session");
                                                                window.location.href = '/order-successful?id=' ;
                                                            } else {
                                                                alert('smth went wrong');
                                                            }
                                        
                                                        });
                                                    }); 
                                                }}
                                            />
                                        </PayPalScriptProvider>
                                    </div>
                                </div>
                                <div className="payment_right">
                                    <div className="overview">
                                        <div className="head">Order Summary</div>
                                        <div className="text">
                                            <div>1x Lifetime Basic Membership</div>
                                            <div>{this.state.email}</div>
                                        </div>
                                        <div className="price">
                                            <div>Total:</div>
                                            <div>$99 USD</div>
                                        </div>
                                    </div>
                                </div>                             
                            </div> 
                            : ""}
                        </div>
                    </Row>
                </div>
                <footer>
                    <div className="container">
                        <ul>
                            {/*<li class="nav-item">
                            <a class="nav-link" href="/datenschutz">FAQ</Link>
                        </li>*/}
                            <li className="nav-item">
                                <Link className="nav-link" to="#contact" onClick={() => this.change_Form("show_contact", true)}>Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/terms-and-conditions">Ts&Cs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/privacy-policy">Privacy & Cookies</Link>
                            </li>
                        </ul>
                        <ul className="payment_list">
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width="38" height="24" role="img" aria-labelledby="pi-paypal"><title id="pi-paypal">PayPal</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><path fill="#003087" d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z"></path><path fill="#3086C8" d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z"></path><path fill="#012169" d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z"></path></svg>
                            </li>
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width="38" height="24" role="img" aria-labelledby="pi-maestro"><title id="pi-maestro">Maestro</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><circle fill="#EB001B" cx="15" cy="12" r="7"></circle><circle fill="#00A2E5" cx="23" cy="12" r="7"></circle><path fill="#7375CF" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path></svg>
                            </li>
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" aria-labelledby="pi-master"><title id="pi-master">Mastercard</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><circle fill="#EB001B" cx="15" cy="12" r="7"></circle><circle fill="#F79E1B" cx="23" cy="12" r="7"></circle><path fill="#FF5F00" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path></svg>
                            </li>
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" aria-labelledby="pi-visa"><title id="pi-visa">Visa</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><path d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z" fill="#142688"></path></svg>
                            </li>
                        </ul>
                        <p className="copyright">©golftùs 2023</p>
                    </div>
                </footer>
            </Container>
        )
    }
}