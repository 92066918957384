import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ModalBoot from '../../components/Modal.js';
import { ReactReader } from 'react-reader';
import Overview from "./Overview.js";
import BucketButtonModal from '../BucketButtonModal.js';
import ItemOverview from '../ItemOverview.js';
import LoadingSVG from '../LoadingSVG.js';
import LoginModal from '../LoginModal.js';
import MembershipFAQ from '../MembershipFAQ.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-tooltip/dist/react-tooltip.css';
import { list_filter } from '../List/functions.js';
import { setCookie, getCookie, delete_cookie } from '../../functions/functions.js';
import $ from 'jquery';
import parse from 'html-react-parser';
import Article from '../Article.js';
import folder from '../folder.json';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
let localhostName = require('../../' + folder._ + '/config.json');
const website_info_all = require('../../' + folder._ + '/Data/website_info.json');
const data = require('../../' + folder._ + '/data.json');
let lang;
let first_m = (getCookie("first_m") || "true");
let master_name;
let website_info;

export default class BookPage extends Component {
    constructor(props) {
        super(props);
        const url = window.location.href;
        const basePath = '/books/';
        const startIndex = url.indexOf(basePath);
        const start = startIndex + basePath.length;
        const book = url.substring(start);

        this.state = {
            language: "de",
            items_filter: [],
            userInfo: this.props.userInfo,
            selected_item: {},
            show_filters: false,
            weather_filter: "",
            weather_month: (new Date).getMonth() + 1,
            continent_filter: [],
            pop_filter: "",
            gol_cont_filter: "",
            search_item: "",
            sort_by: "name",
            filter_country: "",
            not_rainy: false,
            sunbathing: false,
            data_loaded: false,
            filter: [],
            filter_values: {},
            slider_filter: {},
            logged: (this.props.logged || false),
            tracker: {
                items: 0,
                tabs: 0,
                filter: 0,
                cities: 0
            },
            tracker_daily: {
                items: 0,
                tabs: 0,
                filter: 0,
                cities: 0
            },
            items_filters: [],
            tracker_stop: false,
            tracker_stop_daily: false,
            block: false,
            user: (this.props.user ? this.props.user : {}),
            user_data: (this.props.user ? this.props.user.user_data : {}),
            list: [],
            master_name: window.location.pathname.split("/")[2],
            learn_items: [],
            view_style: "Box",
            book: book,
            data: data,
            page_data: {},
            page_info: {},
            item_overview: { tabs: [] },
            website_info: website_info_all[lang || "EN"],
            location: 0,
            urlAfterBooks: ''
        };
        lang = (getCookie("lang") || "EN");
        master_name = window.location.pathname.split("/")[2];
        this.showFormButton = this.showFormButton.bind(this);
        this.showFormButtonItem = this.showFormButtonItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.changeState = this.changeState.bind(this);
        this.onChange = this.onChange.bind(this);
        this.tracker = this.tracker.bind(this);
        this.setState = this.setState.bind(this);
        this.fetchEvents();
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    notLoggedModal(type, text) {
        if (type === "filter") {
            text = "<span>!</span>You have to be logged in to use this filter."
        };
        if (this.state.logged) {
        } else {
            $(".BucketButtonModal").addClass("show");
            $(".must_logged").html(text);
        };
    }
    tracker(type, value) {
        let tracker = (this.state.tracker || {
            items: 0,
            tabs: 0,
            filter: 0,
            cities: 0
        });
        let tracker_daily = (this.state.tracker_daily || {
            items: 0,
            tabs: 0,
            filter: 0,
            cities: 0
        });
        let tracker_stop = this.state.tracker_stop;
        let tracker_stop_daily = this.state.tracker_stop_daily;
        if (type === "item") {
            tracker.items = tracker.items + 1;
            tracker_daily.items = tracker_daily.items + 1;
        };
        if (type === "tab") {
            tracker.tabs = tracker.tabs + 1;
            tracker_daily.tabs = tracker_daily.tabs + 1;
        };
        if (type === "filter") {
            tracker.filter = tracker.filter + 1;
            tracker_daily.filter = tracker_daily.filter + 1;
        };
        this.setState({ tracker, tracker_daily });
        if (tracker.cities > 8 && tracker.tabs > 3 && tracker.filter > 8 && !this.state.logged) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.cities > 14 && tracker.tabs > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.items > 10 && tracker.tabs > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.cities > 30) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.items > 20) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.cities > 7 && tracker.items > 7 && tracker.tabs > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker.cities > 20 && tracker.filter > 8 && !this.state.logged) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.props.refresh();
        };
        if (tracker_daily.cities > 1 || tracker_daily.items > 1 && tracker_daily.tabs > 1 && !this.state.logged) {
            this.setState({ tracker_stop_daily: true });
            setCookie("tracker_stop_daily", true, 1);
            this.props.refresh();
        };
        setCookie("tracker", JSON.stringify(tracker), 365);
        setCookie("tracker_daily", JSON.stringify(tracker_daily), 1);
    }
    componentDidMount() {
        this.extractUrlPart();
        let url = new URL(window.location.href);
        let selected_tab = url.searchParams.get("tab");
        let id = url.searchParams.get("id");
        let pay_suc = url.searchParams.get("success");
        let position = url.searchParams.get("s");
        if (position === "top") {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };
        if (pay_suc === "basic") {
            this.showFormButton("showPaySuc", true);
        };
        let tracker = getCookie("tracker");
        let tracker_stop = getCookie("tracker_stop");
        let tracker_stop_daily = getCookie("tracker_stop_daily");
        this.setState({ tracker_stop, tracker_stop_daily, tracker: JSON.parse(tracker) });
        $(document).click(function (event) {
            var $target = $(event.target);
            if (!$target.closest('.navbar_box').length) {
                if (!$target.closest('.header_navbar .more').length) {
                    $('.navbar_box').removeClass("show_navbar");
                } else {
                    if ($('.navbar_box.show_navbar').length > 0) {
                        $('.navbar_box').removeClass("show_navbar");
                    } else {
                        $('.navbar_box').addClass("show_navbar");
                    };
                }
            }
        });
        this.handlePopState = () => {
            window.location.href = '/';
        };
        window.addEventListener('popstate', this.handlePopState);
        setCookie("content", window.location.pathname, 100);
        if (first_m == "true") {
            $('html, body').scrollTop(0);
            setTimeout(function () {
                $(".overviewtab_info").removeClass("true");
            }, 8000);
            setCookie("first_m", "false", 200);
        };
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: "page", page: master_name })
            .then(res => {
            })
            .catch((error) => {
            });
    }
    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({ user: this.props.user, user_data: this.props.user.user_data })
        }
    }
    fetchEvents() {
        let url = new URL(window.location.href);
        let pathName = localhostName.dm + "/sub/Ebooks";
        axios.get(localhostName.host + '/data_master/' + pathName + '/Books/' + this.state.book)
            .then(res => {
                let data = res.data;
                console.log(data);
                if (data) {
                    this.setState({
                        page_data: data.page_data,
                        page_info: data["EN"],
                        item_overview: data.item_overview_all
                    })
                    this.setState({ data_loaded: true, data });
                } else {
                    this.handleRetry();
                }
            })
            .catch(error => {
                console.error("Error fetching events:", error);
                this.handleRetry();
            });
    }
    handleRetry() {
        if (this.state.retry_count < this.state.max_retries) {
            this.setState(prevState => ({ retry_count: prevState.retry_count + 1 }), () => {
                setTimeout(() => this.fetchEvents(), 1000);
            });
        } else {
            this.setState({ data_loaded: true, data_error: true });
            console.log("Max retries reached. Could not load events.");
        }
    }
    showFormButton(targetShow, change, id, tab) {
        if (targetShow === "showItemOverview") {
            this.setState({ [targetShow]: change });
        };
        if (targetShow === "showItemOverview" && change === false) {
            window.history.replaceState(null, null, window.location.pathname);
        };
        if (targetShow === "showItemOverview" && change === true) {
            window.history.replaceState(null, null, window.location.pathname + "?id=" + id + (tab ? ("&tab=" + tab) : ""));
        };
    }
    showFormButtonItem(targetShow, change, parent) {
        if (targetShow === "showItemOverview" && change == false) {
            this.props.refresh();
        };
        this.setState({ [targetShow]: change, parent: parent });
    }
    selectItem(item) {
        this.setState({ selected_item: item });
    }
    changeState(state, value) {
        this.setState({ [state]: value });
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    sort_by(value) {
        this.setState({ sort_by: value });
    }
    select_page(value) {
        window.location.href = value;
    }
    search_item(e) {
        this.setState({ search_item: e.target.value })
    }
    handleCheckboxChange(e) {
        this.setState({ show_completed: e.target.checked });
    }
    extractUrlPart = () => {
        // Get the current URL
        const url = window.location.href;

        // Define the base path to look for
        const basePath = '/books/';

        // Find the starting index of the base path in the URL
        const startIndex = url.indexOf(basePath);

        if (startIndex !== -1) {
            // Calculate the start index of the part we want
            const start = startIndex + basePath.length;

            // Extract the part of the URL after the base path
            const pathAfterBase = url.substring(start);

            // Update the state with the extracted URL part
            this.setState({ urlAfterBooks: pathAfterBase });
        } else {
            console.log('Base path /books/ not found in the URL.');
        }
    };

    handleLocationChanged = (epubcfi) => {
        // Update the location in the state when it changes
        this.setState({ location: epubcfi });
    };
    render() {
        const { location, urlAfterBooks } = this.state;
        let page_data = this.state.page_data;
        let page_info = this.state.page_info;
        let item_overview = this.state.item_overview;
        let website_info = this.state.website_info;
        let data = this.state.data;
        let page_c = 0;
        this.state.learn_items.map(c => {
            let tabs_c = c.tabs.filter(c => c !== "Notepad");
            page_c += tabs_c.length;
        });
        let text = {
            "EN": {
                completed: "Completed",
                "many_tabs": "Content",
                "change_tabs": "You can switch between the main content at any time."
            },
            "DE": {
                completed: "Erledigt",
                "many_tabs": "Inhalte",
                "change_tabs": "Sie können jederzeit zwischen den Hauptinhalten wechseln."
            }
        };
        return (
            <div className={"items_page desktop AnalyzePage BookPage " + master_name + " " + page_data.page_css + " " + (isMobile && "mobile")}>
                {isBrowser &&
                    <div className="items_page_header">
                        <img src={localhostName.data_url + "/banners/" + page_data.banner} width="400" className="header_video" autoPlay muted loop>
                        </img>
                        <svg viewBox="0 0 1440 120" className="wave" ><path d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path></svg>
                        <div className={"header_navbar " + ("type_" + website_info.nav_type)}>
                            <Link to={website_info.brand_url}><div className="logo_name">{website_info.brand}</div></Link>
                            <div className="links_navbar">
                                {(website_info.technique_pages || []).map(p => {
                                    return (<Link to={p.url}><div className="nav_tab nav_extra" >{p.name}</div></Link>)
                                })}
                                <Link to="/membership" className="t_1"><div className="nav_tab">{website_info.nav_names.membership}</div></Link>
                                <Link to="/faq"><div className="nav_tab betaND">FAQ</div></Link>
                                <a class="dropdown">
                                    <div className="nav_tab">
                                        {website_info.more_nav}
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <div className="techniques">
                                            {website_info.main_content ? <div className="techniques_nav">{website_info.main_nav}</div> : ""}
                                            {(website_info.main_content || []).map(p => {
                                                return (<div class="dropdown-item" onClick={() => { window.location.href = p.url; }} ><img className="nav_icon" src={"/icons/" + p.icon} />{p.name}</div>)
                                            })}
                                            {website_info.main_content ? <hr className="nav_line"></hr> : ""}
                                            <div className="techniques_nav">{website_info.more_nav}</div>
                                            {(website_info.technique_pages || []).map(p => {
                                                return (<div class="dropdown-item" onClick={() => { window.location.href = p.url; }} ><img className="nav_icon" src={"/icons/" + p.icon} /><span>{p.name}</span>
                                                    <p>{p.desc}</p>
                                                </div>)
                                            })}
                                        </div>
                                        <div>
                                            <div class="nav_tab login join">
                                                {this.props.logged ?
                                                    ""
                                                    :
                                                    <Link to="/join"><button >Join</button></Link>
                                                }
                                            </div>
                                            <a class="dropdown-item" href="/membership#faq"><img className="nav_icon" src="/icons/faq.png" />{website_info.nav_names.faq}</a>
                                            <Link class="dropdown-item" to="/aboutus"><img className="nav_icon" src="/icons/group.png" />{website_info.nav_names.about_us}</Link>
                                            <Link class="dropdown-item" to="/contact"><img className="nav_icon" src="/icons/letter.png" />{website_info.nav_names.contact}</Link>
                                            <Link class="dropdown-item" to="/terms-and-conditions"><img className="nav_icon" src="/icons/terms-and-conditions.png" />{website_info.nav_names.tc_cs}</Link>
                                            <Link class="dropdown-item" to="/privacy-policy"><img className="nav_icon" src="/icons/privacy-policy.png" />{website_info.nav_names.privacy}</Link>
                                        </div>
                                    </div>
                                </a>
                                <div className="nav_lang">
                                    {website_info.languages.length > 1 ? website_info.languages.map(l => {
                                        return (
                                            <div onClick={() => { setCookie("lang", l.code, 365); window.location.reload(); }}><img src={"/icons/" + l.icon} />{l.name}</div>
                                        )
                                    }) : ""}
                                </div>
                            </div>
                            <div class="nav_tab login">
                                {this.state.logged ?
                                    <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.state.user.firstname} {this.state.user.lastname.charAt(0)}.</span></Link></div>
                                    :
                                    <button onClick={() => this.showFormButton("showLogin", true)}>Login</button>
                                }
                            </div>
                        </div>
                        <div className="header_content">
                            <div className="header_title">
                                <div className="title_top">E-Book</div>
                                <div className="title_top">{page_info.frontpage_headline}</div>
                                <div className="title_sub">{parse(page_info.frontpage_masterheadline || "")}</div>
                            </div>
                        </div>
                    </div>
                }
                {isMobile &&
                    <div className={"items_page_header" + (this.state.nav_menu ? " nav_menu_open" : "")} >
                        <img src={localhostName.data_url + "/banners_mobile/" + page_data.banner} width="400" className="header_video" onError={(e) => $(e.target).attr(`src`, `/businessproblems_banner.jpg`)} >
                        </img>
                        <div className="overlay_page"></div>
                        <svg viewBox="0 0 1440 120" className="wave" ><path d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path></svg>
                        <nav className="navbar navbar_mobile navbar-expand-lg navbar-light bg-light" >
                            <button onClick={() => this.nav_menu()} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <Link className="navbar-brand" to={website_info.brand_url}><div className="mob_title">{website_info.brand}</div></Link>
                            <div class="nav_tab login">
                                {this.state.logged ?
                                    <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.state.user.firstname} {this.state.user.lastname.charAt(0)}.</span></Link></div>
                                    :
                                    <button onClick={() => this.showFormButton("showLogin", true)}>Login</button>
                                }
                            </div>
                            <div className="navbar-collapse" id="navbarNavAltMarkup">
                                <div className="navbar-nav">
                                    {website_info.main_content ? <div className="techniques_nav">{website_info.main_nav}</div> : ""}
                                    {(website_info.main_content || []).map(p => {
                                        return (<a className="nav-item nav-link" href={p.url} unstable_viewTransition>{p.name}</a>)
                                    })}
                                    {website_info.main_content ? <hr className="nav_line"></hr> : ""}
                                    <div className="techniques_nav">{website_info.more_nav}</div>
                                    {(website_info.technique_pages || []).map(p => {
                                        return (<a className="nav-item nav-link" href={p.url} unstable_viewTransition><img className="nav_icon" src={"/icons/" + p.icon} />{p.name}</a>)
                                    })}
                                    <hr className="nav_line"></hr>
                                    <Link className="nav-item nav-link" to="/membership">{website_info.nav_names.membership}</Link>
                                    <Link className="nav-item nav-link" to="/aboutus">{website_info.nav_names.about_us}</Link>
                                    <Link className="nav-item nav-link" to="/contact">{website_info.nav_names.contact}</Link>
                                    <Link className="nav-item nav-link" to="/terms-and-conditions">{website_info.nav_names.tc_cs}</Link>
                                    <Link className="nav-item nav-link" to="/privacy-policy">{website_info.nav_names.privacy}</Link>
                                    <hr className="nav_line"></hr>
                                    <div class="nav_tab login">
                                        {this.state.logged ?
                                            <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.state.user.firstname} {this.state.user.lastname.charAt(0)}.</span></Link></div>
                                            :
                                            <button onClick={() => this.showFormButton("showLogin", true)}>Login</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="nav_lang">
                            {website_info.languages.length > 1 ? website_info.languages.map(l => {
                                return (
                                    <div onClick={() => { setCookie("lang", l.code, 365); window.location.reload(); }}><img src={"/icons/" + l.icon} />{l.name}</div>
                                )
                            }) : ""}
                        </div>
                        <div className="header_title">
                            <div className="title_top">{page_info.frontpage_headline}</div>
                            <div className="title_sub">{page_info.frontpage_masterheadline}</div>
                        </div>
                    </div>
                }
                <Article />
                <div className="">
                    <div className="short_desc">
                        <h3>Book Info</h3>
                        <div>{parse(page_info.short_desc || "")}</div>
                    </div>
                    <div className="ebook_reader">
                        <div style={{ height: '800px', width: '90%', "max-width": '1200px'}}>
                            <ReactReader
                                url="/happy.epub"
                                location={location}
                                locationChanged={this.handleLocationChanged}
                                showToc={true}
                                swipeable={true}
                            />
                        </div>
                    </div>
                </div>
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
                </ModalBoot >
            </div>
        )

    }
}    
